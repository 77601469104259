import { createDecorator } from 'vue-class-component';
import { ComputedOptions } from 'vue';

export * from 'vue-property-decorator';
export * from 'vuex-class';
// export * from 'core-decorators';

// Custom decorator, E.g:

export const NoCache = createDecorator((options, key) => {
    // component options should be passed to the callback
    // and update for the options object affect the component
    if (options.computed) (options.computed[key] as ComputedOptions<any>).cache = false;
});

const getFnName = function () {
    return this.name || this.toString().match(/function\s*([^(]*)\(/)[1];
};

export const AsyncComputed = function (
    promise,
    options: { immediate?: boolean; defaultValue?: any; defaultParams?: any } = {
        immediate: false,
        defaultValue: undefined,
        defaultParams: undefined,
    }
) {
    return function (target, key) {
        createDecorator((componentOptions, key) => {
            componentOptions.mixins.push({
                data: () => ({ [key]: options.defaultValue }),
            });
            componentOptions.methods || (componentOptions.methods = {});
            if (Array.isArray(promise)) {
                promise.forEach((item) => {
                    const name = `asyncComputed$${getFnName.call(item)}`;
                    componentOptions.methods[name] = function (params = options.defaultParams) {
                        return item(params).then((res) => {
                            this[key] = res;
                        });
                    };
                });
            } else {
                const name = `asyncComputed$${getFnName.call(promise)}`;
                componentOptions.methods[name] = function (params = options.defaultParams) {
                    return promise(params).then((res) => {
                        this[key] = res;
                    });
                };
                if (options.immediate) {
                    componentOptions.watch || (componentOptions.watch = {});
                    componentOptions.watch[name] = {
                        handler: function (fn) {
                            fn();
                        },
                        immediate: true,
                    };
                }
            }
        })(target, key);
    };
};

export const Request = function (requests = [], immediate = false) {
    return createDecorator((componentOptions, key) => {
        componentOptions.methods || (componentOptions.methods = {});
        requests.forEach((item) => {
            const name = `request$${getFnName.call(item)}`;
            componentOptions.methods[name] = function (params) {
                return item(params);
            };
        });
    });
};

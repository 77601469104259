








































































import { Vue, Component, Mixins } from '@/decorators';
import TemplateMixin from '@/views/hoc/template-item.vue';
import { Prop, Watch } from 'vue-property-decorator';
import api from '@/api';

@Component
export default class TplMtMchatAnnotation extends Vue {
    @Prop()
    result!: any;
    @Prop()
    config!: any;
    round = 0;
    messageArr: any = [];
    @Prop()
    caseId!: any;
    @Prop({ default: () => [] })
    prior!: any;
    curMessage: any = '';
    loading: any = false;
    sessionId: any = '';
    disabled: any = false;
    roleArr: any = {
        user: 0,
        assistant: 1,
    };
    userInfo = localStorage.getItem('User_Info');
    @Watch('result', { immediate: true })
    async handleResultChange(val) {
        if (val) {
            this.disabled = val.baseInfo ? true : false;
            this.messageArr = val.baseInfo ? val.baseInfo : [];
        }
    }

    get minRound() {
        return this.config.minRound || 2;
    }
    endChat() {
        // 可能存在某轮次没有调用成功的问题，过滤掉messageArr轮次中答案为空的
        this.$emit(
            'end',
            this.messageArr.filter((item) => item.answer)
        );
    }

    async send() {
        if (!this.sessionId) {
            let Idres = await this.getSessionId();
            if (Idres?.rescode === 0) {
                this.sessionId = Idres.sessionId;
            } else {
                this.$mtd.message.error('新建会话失败，请检查或刷新重试');
                return;
            }
        }
        this.messageArr.push({});
        this.messageArr[this.messageArr.length - 1].question = this.curMessage;
        // 发送消息聊天请求
        let res = await this.sendMessage();
        // 如果没有请求成功，不会结束对话
        if (res?.rescode !== 0) {
            this.$mtd.message.error('获取模型结果失败，请检查或刷新重试');
            return;
        }
        this.messageArr[this.messageArr.length - 1].answer = res.msg;
        if (this.config.round <= ++this.round) {
            this.disabled = true;
            this.endChat();
        }
    }
    // 创建会话
    async getSessionId() {
        try {
            this.loading = true;
            // type: 0 单轮 1多轮超时 2 多轮永久
            // channelId: 2 标注平台的caseId
            // channelType: 2 web-bot
            // maxRound: 最大轮数
            const type = this.config.round === 1 ? 0 : 1;
            const data = {
                type,
                channelId: this.caseId,
                channelType: 2,
                maxRound: this.config.round,
                model: this.config.model || null,
                messages: [],
                creator: this.userInfo,
                source: 2,
            };
            if (this.prior.input) {
                let priorRound = 0;
                this.prior.input.forEach((item) => {
                    data.maxRound += 0.5; // 1问1答为1轮
                    priorRound += 0.5;
                    data.messages.push({
                        content: item.content,
                        type: this.roleArr[item.role],
                        round: Math.round(priorRound),
                    });
                });
            }
            const res = await api.common.createSession(data);
            console.log(res, 'getSessionId');
            return res;
        } catch (e) {
            console.log(this, 'error');
            this.$mtd.message.error(e || '新建会话失败，请检查或刷新重试');
        } finally {
            this.loading = false;
        }
    }
    // chat
    async sendMessage() {
        try {
            this.loading = true;
            const data = {
                sessionId: this.sessionId,
                message: this.curMessage,
                operator: this.userInfo,
            };
            this.curMessage = '';
            const res = await api.common.chat(data);
            console.log(res, 'sendMessage');
            return res;
        } catch (e) {
            this.$mtd.message.error(e || '获取模型结果失败，请检查或刷新重试');
        } finally {
            this.loading = false;
        }
    }
    // 关闭对话
    async closeMessage() {
        try {
            this.loading = true;
            const data = {
                sessionId: this.sessionId,
                operator: this.userInfo,
            };
            const res = await api.common.closeSession(data);
            console.log(res, 'closeSession');
            if (res.rescode === 0) {
                this.disabled = true;
                this.endChat();
            }
        } catch (e) {
            this.$mtd.message.error(e || '结束模型调用失败，请检查或刷新重试');
        } finally {
            this.loading = false;
        }
    }
}

const formItemSchema = {
    formItemType: 'el-radio-group',
    label: '单选框',
    vModel: 'radio',
    required: false,
    defaultValue: '',
    options: [{ label: '单选框1' }, { label: '单选框2' }],
};
const formItemConfig = {
    formStyle: {
        labelWidth: '100',
        size: 'mini',
        span: 20,
    },
    formItemList: [
        {
            formItemType: 'el-input',
            label: '名称',
            vModel: 'label',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '绑定值',
            tips: '区块内唯一Key值',
            vModel: 'vModel',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '未标注值',
            tips: '未标注时的默认结果',
            vModel: 'noAnnotationDefaultValue',
        },
        {
            formItemType: 'el-radio-group',
            label: '是否必填',
            vModel: 'required',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'input-plus',
            defaultValue: [],
            label: '选项配置',
            vModel: 'options',
            required: true,
            attr: {
                inputConfig: [
                    { name: '单选框值', vModel: 'label' },
                    { name: '单选框名', vModel: 'name' },
                ],
            },
        },
        {
            formItemType: 'el-input-number',
            label: '宽度',
            vModel: 'span',
            attr: { type: 'number', min: 1, max: 24 },
        },
    ],
};
const formItemInfo = {
    name: '单选框',
};
export default { formItemSchema, formItemConfig, formItemInfo };













































































import { Vue, Component, Watch, Mixins } from '@/decorators';
import { annotationConfig, categoryList } from '../../helper/constant';
import { CaseDatas } from '@/model/modules/admin';
import CreateQrcode from '@/components/CreateQrcode.vue';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';

@Component({
    components: { CreateQrcode },
})
export default class TplGoTestAnnotation extends Vue {
    annotationConfig = annotationConfig;
    categoryList = categoryList;
    fromWhere: string;
    dataSource: CaseDatas;
    $refs: { form: any; qrcode: any };
    $parent: any;
    simulatorScroll = false;
    remarkStr = '';

    // 标注状态
    annotationResult: any = {
        picList: [],
        result: '',
        reason: '',
        position: [],
    };
    picList = [
        { uploadTime: '', url: '', type: 'left' },
        { uploadTime: '', url: '', type: 'right' },
    ];

    suggestInfo: any = null;
    reasonStr = ''; // 不通过的标注理由
    caseData: CaseDatas = null;
    saveBtnLoading = false;
    timer = null;
    user = localStorage.getItem('User_Info');
    caseInfo: any = {
        query: '',
    };
    picKey = {
        left: '',
        right: '',
    };
    showqrcode = {
        left: false,
        right: false,
    };
    districtParam = {
        region: '',
        regionType: 4,
    };
    districtRes = {
        provincial: [],
        city: [],
        area: [],
    };
    positonRes = [undefined, undefined, undefined];
    config = {};
    showKey(type) {
        this.$mtd.message.success(this.picKey[type]);
    }

    async mounted() {
        this.initState();
        let { taskId, caseId } = this.$route.query;
        if (!taskId) {
            this.$mtd.message.error('您还没有选择要标注的任务，请先选择标注任务');
            Vue.nextTick(() => {
                this.$router.push('/task-list');
            });
            return void 0;
        }
        if (caseId) {
            await this.getCaseById(taskId, caseId);
        } else {
            await this.getNextCase(taskId, caseId);
        }
        await this.getDistrictRes(4);
    }
    async getNextCase(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getNextCase({ taskId: taskId, currentCaseId: caseId || null });
        let data = res.data;
        if (data) {
            this.saveBtnLoading = false;
            this.caseData = data;
            this.getCasePicKey('left');
            this.getCasePicKey('right');
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    async getCaseById(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getCaseById({ caseId: caseId });
        this.saveBtnLoading = false;
        let data = res.data;
        if (data) {
            this.caseData = data;
            this.getCasePicKey('left');
            this.getCasePicKey('right');
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    @Watch('picList', { deep: true, immediate: true })
    onPicListChange(val) {
        if (val[0].url !== '' && val[1].url !== '') {
            window.clearInterval(this.timer);
        }
    }

    getContent(type) {
        let app = this.categoryList[this.config[type]];
        let query = this.caseInfo.query;
        let url = `${window.location.origin}/mark/upload?app=${app}&query=${query}&picKey=${this.picKey[type]}`;
        const isProd = process.env.CONFIG_ENV === 'production';
        if (isProd) {
            url = `${window.location.origin}/upload?app=${app}&query=${query}&picKey=${this.picKey[type]}`;
        }
        return url;
    }

    async getCasePicKey(key) {
        this.picKey[key] = '';
        let params = {
            caseId: this.caseData.caseId,
            type: key,
        };
        let res = await this.$api.common.getCasePicKey(params);
        let data = res.data;
        if (data) {
            this.picKey[key] = data.picKey;
        }
    }

    initState() {
        this.reasonStr = '';
        this.caseData = null;
        this.simulatorScroll = false;
        this.saveBtnLoading = true;
        this.annotationResult = {
            picList: [],
            result: '',
            reason: '',
            position: null,
        };
        this.showqrcode.left = true;
        this.showqrcode.right = true;
        this.picList = [
            { uploadTime: '', url: '', type: 'left' },
            { uploadTime: '', url: '', type: 'right' },
        ];
        this.config = {};
    }

    dealCaseData() {
        this.timer = window.setInterval(this.getCasePic, 1000);
        this.getCasePic();
        let { annotationResult, suggestInfo, taskId, caseInfo, queryInfo, config } = this.caseData;
        this.caseInfo = caseInfo;
        this.getConfig(config);
        if (suggestInfo) {
            suggestInfo = JSON.parse(suggestInfo);
            suggestInfo.annotationResultCheck = JSON.parse(suggestInfo.annotationResultCheck);
            this.suggestInfo = suggestInfo;
        }
        // 如果存在标注数据，进行赋值操作，为了之后和质检的结果进行对比
        annotationResult && (this.annotationResult = JSON.parse(annotationResult));
        // 赋值图片list
        if (this.annotationResult.picList.length > 0) {
            this.picList = cloneDeep(this.annotationResult.picList);
            this.showqrcode.left = !this.picList.find((i) => i.type == 'left').url;
            this.showqrcode.right = !this.annotationResult.picList.find((i) => i.type == 'right').url;
        }
        //优先取annotationResult里的position
        if (this.caseInfo.region) {
            this.positonRes = this.caseInfo.region.split(',');
        }
        if (this.annotationResult.position) {
            this.positonRes = this.annotationResult.position;
        }
    }
    getConfig(config) {
        if (config && typeof config === 'string') {
            this.config = JSON.parse(config);
        } else if (config) {
            this.config = config;
        }
    }

    deletePic(type) {
        let idx = type == 'left' ? 0 : 1;
        this.getCasePicKey(type);
        this.$refs.qrcode[idx].createQrcode(this.getContent(type));
        this.showqrcode[type] = true;
        this.timer = window.setInterval(this.getCasePic, 1000);
    }

    async getCasePic() {
        let param = {
            taskId: this.caseData.taskId,
            caseId: this.caseData.caseId,
            left: true, // true-获取左侧图片，false-不获取左侧图片
            right: true, // true-获取右侧图片，false-不获取右侧图片
        };
        let res = await this.$api.common.getCasePic(param);
        let data = res.data;
        if (data) {
            if (this.showqrcode.left) {
                this.dealPic('left', data);
            }
            if (this.showqrcode.right) {
                this.dealPic('right', data);
            }
        }
    }

    dealPic(type, data) {
        let pic = this.picList.find((i) => i.type == type);
        if (pic.url == '') {
            // 新任务、无图片
            if (data[`${type}PicUrl`]) {
                // 获取到上传的数据赋值，踢掉二维码
                pic.url = data[`${type}PicUrl`];
                pic.uploadTime = dayjs(data[`${type}PicUploadTime`]).format('YYYY-MM-DD HH:mm:ss');
                this.showqrcode[type] = false;
            } else {
                // 继续轮询
                this.showqrcode[type] = true;
            }
        } else {
            // 已上传图片、删掉后重新扫码上传，对比旧数据和新数据
            if (data[`${type}PicUrl`]) {
                // 新旧一致，还没上传数据，展示二维码等待数据
                if (pic.url == data[`${type}PicUrl`]) {
                    this.showqrcode[type] = true;
                } else {
                    // 新旧不一致，获取到了新数据，替换并踢掉二维码
                    pic.url = data[`${type}PicUrl`];
                    pic.uploadTime = dayjs(data[`${type}PicUploadTime`]).format('YYYY-MM-DD HH:mm:ss');
                    this.showqrcode[type] = false;
                }
            }
        }
    }

    checkData() {
        if (this.picList[0].url == '' || this.picList[1].url == '' || this.showqrcode.left || this.showqrcode.right) {
            this.$mtd.message.error('请先扫码上传图片');
            return void 0;
        }
        this.annotationResult.picList = cloneDeep(this.picList);
        if (this.annotationResult.result == '') {
            this.$mtd.message.error('请进行结果标注');
            return void 0;
        }
        if (!this.positonRes[0] || !this.positonRes[1] || !this.positonRes[2]) {
            this.$mtd.message.error('请选择定位');
            return void 0;
        }
        this.annotationResult.position = this.positonRes;
        return true;
    }

    async onTempSave() {
        if (!this.checkData()) {
            return void 0;
        }
        let { taskId, caseId } = this.$route.query;
        this.saveBtnLoading = true;
        try {
            await this.$api.common.setCaseDistrict({ caseId: this.caseData.caseId, region: this.annotationResult.position.join(',') });
            let saveCaseInfo: any = {
                caseId: this.caseData.caseId,
                annotationResult: JSON.stringify(this.annotationResult),
            };
            let res = await this.$api.common.tempSaveCase(saveCaseInfo);
            if (res.rescode === 0) {
                this.saveBtnLoading = false;
                this.goNext(taskId, caseId);
                this.initState();
            }
        } catch (e) {
            console.log(e);
            this.saveBtnLoading = false;
        }
    }

    goNext(taskId, caseId) {
        if (caseId) {
            // 从列表过来的case
            this.$router.push({ path: '/task-temp', query: { taskId } });
        } else {
            // 正常标注的case
            this.getNextCase(this.caseData.taskId, this.caseData.caseId);
        }
    }

    async getPrev() {
        window.clearInterval(this.timer);
        let res = await this.$api.common.getPreviousCase({ taskId: this.caseData.taskId, currentCaseId: this.caseData.caseId });
        let data = res.data;
        if (data) {
            this.caseData = data;
            this.dealCaseData();
        } else {
            this.initState();
        }
    }
    beforeDestroy() {
        this.initState();
        window.clearInterval(this.timer);
    }

    async getDistrictRes(type, region?) {
        if (type == 5) this.positonRes[1] = this.positonRes[2] = '';
        if (type == 6) this.positonRes[2] = '';
        let param = {
            region: region,
            regionType: type,
        };
        let res = await this.$api.common.district(param);
        let data = res.data;
        if (data) {
            if (type == 5) {
                this.districtRes.city = data.adminList.map((i) => i.name);
            } else if (type == 6) {
                this.districtRes.area = data.adminList.map((i) => i.name);
            } else {
                this.districtRes.provincial = data.adminList.map((i) => i.name);
            }
        }
    }
}

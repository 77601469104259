import * as model from '@/model';
// import { http } from '../http';
import * as http from '../http';
const { get, post, put, del } = http.http;

/**
 * 按名称或者misId搜索用户
 */
export function searchUser(keyword: string) {
    return http.http.get<model.user.IUser[]>('/user', {
        params: {
            keyword,
        },
    });
}

// examples
export function getCurrentUserInfo<T = model.user.IUser>() {
    return http.http.get<T>('/user/current');
}
export function updateUserInfo<T = model.user.IUser>(data: { old: T; new: T }) {
    return http.http.put<boolean>('/user', { data, rethrowError: true });
}
export function addUser<T = model.user.IUser>(data: T) {
    return http.http.post<boolean>('/user', { data, rethrowError: true });
}
export function deleteUser<T = model.user.IUser>(data: T) {
    return http.http.del<boolean>('/user', { data, rethrowError: true });
}

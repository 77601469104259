
































































import { Vue, Component, Watch, Mixins } from '@/decorators';
import { annotationConfig, categoryList } from '../../helper/constant';
import { CaseDatas } from '@/model/modules/admin';

@Component({})
export default class TplGoTestAnnotation extends Vue {
    annotationConfig = annotationConfig;
    categoryList = categoryList;
    fromWhere: string;
    dataSource: CaseDatas;
    $refs: { form: any; qrcode: any };
    $parent: any;
    simulatorScroll = false;
    remarkStr = '';

    // 标注状态
    annotationResult: any = {};
    picList = [
        { uploadTime: '', url: '', type: 'left' },
        { uploadTime: '', url: '', type: 'right' },
    ];

    suggestInfo: any = null;
    reasonStr = ''; // 不通过的标注理由
    caseData: CaseDatas = null;
    saveBtnLoading = false;
    timer = null;
    user = localStorage.getItem('User_Info');
    caseInfo: any = {
        query: '',
    };
    picKey = {
        left: '',
        right: '',
    };
    showqrcode = {
        left: false,
        right: false,
    };
    districtParam = {
        region: '',
        regionType: 4,
    };
    districtRes = {
        provincial: [],
        city: [],
        area: [],
    };
    positonRes = [undefined, undefined, undefined];
    config = {
        question: [],
        answer: [],
    };

    async mounted() {
        this.initState();
        let { taskId, caseId } = this.$route.query;
        if (!taskId) {
            this.$mtd.message.error('您还没有选择要标注的任务，请先选择标注任务');
            Vue.nextTick(() => {
                this.$router.push('/task-list');
            });
            return void 0;
        }
        if (caseId) {
            await this.getCaseById(taskId, caseId);
        } else {
            await this.getNextCase(taskId, caseId);
        }
        // await this.getDistrictRes(4);
    }
    async getNextCase(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getNextCase({ taskId: taskId, currentCaseId: caseId || null });
        let data = res.data;
        if (data) {
            this.saveBtnLoading = false;
            this.caseData = data;
            // this.getCasePicKey('left');
            // this.getCasePicKey('right');
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    async getCaseById(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getCaseById({ caseId: caseId });
        this.saveBtnLoading = false;
        let data = res.data;
        if (data) {
            this.caseData = data;
            // this.getCasePicKey('left');
            // this.getCasePicKey('right');
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    @Watch('picList', { deep: true, immediate: true })
    onPicListChange(val) {
        if (val[0].url !== '' && val[1].url !== '') {
            window.clearInterval(this.timer);
        }
    }

    initState() {
        this.reasonStr = '';
        this.caseData = null;
        this.simulatorScroll = false;
        this.saveBtnLoading = true;
        this.annotationResult = {};
        this.showqrcode.left = true;
        this.showqrcode.right = true;
        this.picList = [
            { uploadTime: '', url: '', type: 'left' },
            { uploadTime: '', url: '', type: 'right' },
        ];
        this.config = {
            question: [],
            answer: [],
        };
    }

    dealCaseData() {
        // this.timer = window.setInterval(this.getCasePic, 1000);
        // this.getCasePic();
        let { annotationResult, suggestInfo, taskId, caseInfo, queryInfo, config } = this.caseData;
        this.caseInfo = caseInfo;
        this.getConfig(config);
        if (suggestInfo) {
            suggestInfo = JSON.parse(suggestInfo);
            suggestInfo.annotationResultCheck = JSON.parse(suggestInfo.annotationResultCheck);
            this.suggestInfo = suggestInfo;
        }
        // 如果存在标注数据，进行赋值操作，为了之后和质检的结果进行对比
        annotationResult && (this.annotationResult = JSON.parse(annotationResult));
        //优先取annotationResult里的position
        if (this.caseInfo.region) {
            this.positonRes = this.caseInfo.region.split(',');
        }
        if (this.annotationResult.position) {
            this.positonRes = this.annotationResult.position;
        }
    }
    getConfig(config) {
        if (config && typeof config === 'string') {
            this.config = JSON.parse(config);
        } else if (config) {
            this.config = config;
        }
    }

    checkData() {
        for (let item of this.config?.question) {
            if (item.required && !this.annotationResult[item.name]) {
                this.$mtd.message.error(`请检查${item.label}标注项`);
                return false;
            }
        }
        for (let item of this.config?.answer) {
            if (item.required && !this.annotationResult[item.name]) {
                this.$mtd.message.error(`请检查${item.label}标注项`);
                return false;
            }
        }
        return true;
    }

    async onTempSave() {
        if (!this.checkData()) {
            return void 0;
        }
        let { taskId, caseId } = this.$route.query;
        this.saveBtnLoading = true;
        try {
            // await this.$api.common.setCaseDistrict({ caseId: this.caseData.caseId, region: this.annotationResult.position.join(',') });
            let saveCaseInfo: any = {
                caseId: this.caseData.caseId,
                annotationResult: JSON.stringify(this.annotationResult),
            };
            let res = await this.$api.common.tempSaveCase(saveCaseInfo);
            // this.$parent.setCaseData(saveCaseInfo);
            if (res.rescode === 0) {
                this.saveBtnLoading = false;
                this.goNext(taskId, caseId);
                this.initState();
            }
        } catch (e) {
            console.log(e);
            this.saveBtnLoading = false;
        }
    }

    goNext(taskId, caseId) {
        if (caseId) {
            // 从列表过来的case
            this.$router.push({ path: '/task-temp', query: { taskId } });
        } else {
            // 正常标注的case
            this.getNextCase(this.caseData.taskId, this.caseData.caseId);
        }
    }

    async getPrev() {
        // window.clearInterval(this.timer);
        let res = await this.$api.common.getPreviousCase({ taskId: this.caseData.taskId, currentCaseId: this.caseData.caseId });
        let data = res.data;
        if (data) {
            this.caseData = data;
            this.dealCaseData();
        } else {
            this.initState();
        }
    }
    beforeDestroy() {
        this.initState();
    }

    filterContent(data) {
        return data.replace(/</g, '&lt').replace(/>/g, '&gt'); // 显示html标签代码片段
    }
}

import { ActionTree, GetterTree, MutationTree, Store } from 'vuex';
import api from '@/api';
import { Message } from '@ss/mtd-vue';
import Cookies from 'js-cookie';
import $router from '@/router';
import { refresh } from '@/api/http';

declare interface ICommonState {
    loginName: string;
}

const state: ICommonState = {
    // 用户登录信息
    loginName: '',
};

const getters: GetterTree<ICommonState, any> = {
    userInfo() {
        return state.loginName;
    },
};

const actions: ActionTree<ICommonState, any> = {
    async loginByMobile({ commit }: any, params: any) {
        let res = await api.common.loginByMobile(params);
        if (res) {
            if (res.rescode === 500) {
                Message.error(`登录失败-${res.data.message}`);
                return res.rescode;
            } else {
                const data = res.data;
                let { loginStatus, token, platform } = data;
                if (loginStatus) {
                    if (loginStatus == 0) {
                        Message.error('登录失败');
                    } else if (loginStatus == 1) {
                        Cookies.set('cmark_pub_token', token);
                        Cookies.set('cmark_pub_platform', platform);
                        refresh();
                        commit('userStatus', { loginName: params.loginName });
                        $router.push('/task-list');
                        Message.success('登录成功');
                    } else if (loginStatus == 2) {
                        commit('userStatus', { loginName: params.loginName });
                        Message.success('请修改初始密码');
                    }
                    return loginStatus;
                }
            }
        }
    },
    async updatePassword({ commit }: any, params: any) {
        let res = await api.common.updatePassword(params);
        if (res) {
            Message.success('修改密码成功');
            return true;
        }
    },
    async getCaptchaPicture({ commit }: any, params: any) {
        let res = await api.common.getCaptchaPicture();
        return res;
    },
    async getWaterMark({ commit }: any, params: any) {
        let res = await api.common.getWaterMark();
        return res;
    },
};

const mutations: MutationTree<ICommonState> = {
    userStatus(localState: ICommonState, params: any) {
        localStorage.setItem('User_Info', params.loginName || state.loginName);
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

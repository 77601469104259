const formItemSchema = {
    formItemType: 'input-plus',
    label: '动态输入框',
    vModel: 'inputPlus',
    required: false,
    defaultValue: [],
    attr: {
        showTitle: true,
    },
};
const formItemConfig = {
    formStyle: {
        labelWidth: '100',
        size: 'mini',
        span: 20,
    },
    formItemList: [
        {
            formItemType: 'el-input',
            label: '名称',
            vModel: 'label',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '绑定值',
            tips: '区块内唯一Key值',
            vModel: 'vModel',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '未标注值',
            tips: '未标注时的默认结果',
            vModel: 'noAnnotationDefaultValue',
        },
        {
            formItemType: 'el-radio-group',
            label: '是否必填',
            vModel: 'required',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'input-plus',
            defaultValue: [],
            label: '输入框配置',
            vModel: 'attr.inputConfig',
            required: true,
            attr: {
                inputConfig: [
                    { name: '输入框名', vModel: 'name' },
                    { name: '绑定值', vModel: 'vModel' },
                    { name: '默认值', vModel: 'defaultValue' },
                ],
            },
        },
        {
            formItemType: 'el-radio-group',
            label: '显示输入框名',
            vModel: 'attr.showTitle',
            options: [
                { label: true, name: '显示' },
                { label: false, name: '不显示' },
            ],
        },
        {
            formItemType: 'el-input-number',
            label: '宽度',
            vModel: 'span',
            attr: { type: 'number', min: 1, max: 24 },
        },
    ],
};
const formItemInfo = {
    name: '动态输入框',
};

export default { formItemSchema, formItemConfig, formItemInfo };

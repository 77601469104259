



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { taskListTableConfig, TaskStatus } from '../helper/constant';
import Table from '@/components/table.vue';

@Component({
    components: { Table },
})
export default class TaskList extends Vue {
    taskListTableConfig = taskListTableConfig;
    TaskStatus = TaskStatus;
    taskList = [{}];
    page = {
        total: 0,
        current: 1,
        size: 20,
    };
    loading = true;

    mounted() {
        this.loading = false;
        this.listAnnoTask(this.page.size, this.page.current).then((data) => {
            this.loading = false;
        });
    }
    async listAnnoTask(pageSize, pageNum) {
        let res = await this.$api.common.listAnnoTask({ pageSize: pageSize, pageNum: pageNum });
        let data = res.data;
        if (data) {
            this.page.total = data.total;
            this.taskList = data.taskList;
        }
    }

    handleAnnotation(rowdata) {
        this.$router.push({ path: '/task-work', query: { taskType: rowdata.taskType, taskId: rowdata.taskId } });
    }
    handleTemp(rowdata) {
        this.$router.push({ path: '/task-temp', query: { taskType: rowdata.taskType, taskId: rowdata.taskId } });
    }
    handleChange(current, size) {
        this.listAnnoTask(size, current).then((data) => {
            this.loading = false;
        });
    }

    getTagType(taskStatus) {
        taskStatus = parseInt(taskStatus);
        switch (taskStatus) {
            case 0:
                return 'info';
            case 1:
                return '';
            case 2:
                return 'warning';
            case 3:
                return 'warning';
            case 4:
                return 'success';
            default:
                return '';
        }
    }
}

import Table from '@/components/table.vue';
import AnnotationCaseButtons from '@/components/annotationCaseButtons.vue';
import AnnotationDevTip from '@/components/annotationDevTip.vue';
import AnnotationReworkTip from '@/components/annotationReworkTip.vue';

export default {
    install(Vue: any, options: any) {
        Vue.component('Table', Table);
        Vue.component('AnnotationCaseButtons', AnnotationCaseButtons);
        Vue.component('AnnotationDevTip', AnnotationDevTip);
        Vue.component('AnnotationReworkTip', AnnotationReworkTip);
    },
};





















import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import UnderlineWordItem from './UnderlineWordItem.vue';
@Component({
    components: {
        UnderlineWordItem,
    },
})
export default class UnderlineWord extends Vue {
    @Ref('wording') wordingRef!: UnderlineWordItem[];
    @Prop() underlineWordData!: any;
    @Prop() historyData!: any;
    @Prop({ default: false }) readonly!: boolean;

    annotationResult = {};

    checkWordings(refValue) {
        let wordResult = this.$refs.wording[refValue]?.getAnnotationResult();
        if (!wordResult) {
            return {
                checked: false,
            };
        }

        let reasoning = [];
        let textHtml = this.underlineWordData[refValue].content;
        for (let i in wordResult) {
            if (['length', 'paths', 'cityAnnotation'].includes(i)) continue;
            let chooseInfo = wordResult[i].chooseInfo;
            textHtml = wordResult[i].textHtml;
            for (let item = 0; item < chooseInfo.length; item++) {
                reasoning.push({
                    ...chooseInfo[item],
                });
            }
        }
        const curAnnotationResult = {
            reasoning,
            textHtml,
            checked: true,
        };
        return curAnnotationResult;
    }

    async checkAndCreateData(succCb) {
        let allWordingRes = this.wordingRef.map((item, idx) => this.checkWordings(idx));
        let isHaveIllegalResult = allWordingRes.find((result) => !result);
        if (isHaveIllegalResult) {
            return void 0;
        } else {
            this.annotationResult = {
                wordings: allWordingRes,
            };
            succCb && succCb();
            return true;
        }
    }

    getData() {
        let data = null;
        this.checkAndCreateData(() => {
            data = this.annotationResult;
        });
        return data;
    }

    mounted() {
        if (this.historyData.wordings?.length) {
            this.wordingRef.forEach((wording, i) => {
                if (!this.historyData.wordings[i]) {
                    return false;
                }
                const { reasoning, textHtml } = this.historyData.wordings[i];
                wording.setResult(reasoning, textHtml);
            });
        }
    }
}

























import { Vue, Prop, Component } from '@/decorators';

@Component
export default class ChangePassword extends Vue {
    $refs: { form: any };
    modalShow = false;
    @Prop({ default: false })
    closable;

    password = {
        oldPassword: '',
        newPassword: '',
        newCheck: '',
    };
    rules = {
        oldPassword: [{ required: true, trigger: 'change', validator: this.oldPasswordValid }],
        newPassword: [{ required: true, trigger: 'change', validator: this.newPasswordValid }],
        newCheck: [{ required: true, trigger: 'change', validator: this.passwordCheck }],
    };
    oldPasswordValid(rule, value, callback) {
        if (value === '') {
            callback(new Error('请输入原始密码'));
        } else {
            callback();
        }
    }
    newPasswordValid(rule, value, callback) {
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
        if (value === '') {
            callback(new Error('请输入新密码'));
        } else if (!reg.test(value)) {
            callback(new Error('密码至少包含大写字母，小写字母，数字，且不少于8位'));
        } else {
            callback();
        }
    }
    passwordCheck(rule, value, callback) {
        if (value === '') {
            callback(new Error('请再次确认密码'));
        } else if (value !== this.password.newPassword) {
            callback(new Error('输入与新密码不相同'));
        } else {
            callback();
        }
    }

    clearData() {
        this.$refs.form.resetFields();
    }

    showModal() {
        this.modalShow = true;
    }
    changePasswordFun() {
        this.$refs.form.validate(async (valid) => {
            if (valid) {
                let params = {
                    loginName: localStorage.getItem('User_Info'),
                    oldPassword: this.password.oldPassword,
                    newPassword: this.password.newPassword,
                };
                let res = this.$store.dispatch('updatePassword', params);
                if (res) {
                    this.clearData();
                    this.modalShow = false;
                    this.$emit('changePassword');
                }
            }
        });
    }
}









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnnotationCaseButtons extends Vue {
    @Prop()
    loading: boolean;
    @Prop({ default: () => ({}) })
    actions: { getPrev: any; getNext?: any; onTempSave: any; pass: any; fail: any };

    get caseId() {
        return this.$route.query.caseId;
    }
}

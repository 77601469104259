














































































































import { Vue, Component, Prop } from 'vue-property-decorator';

interface IModel {
    model_id: number | number[];
    temperature: number;
    top_k: number;
    top_p: number;
    frepetition_penalty: number;
    max_tokens: string;
    prompt: string;
    prefix?: string;
    suffix?: string;
}

@Component
export default class ModelDetailItem extends Vue {
    @Prop() model!: IModel;
    @Prop() isEdit!: boolean;
    @Prop() isDoubleBlind!: number;

    parameter = {
        top_p: 0,
        top_k: 1,
        temperature: 0,
        max_tokens: 512,
        frepetition_penalty: 0,
        prompt: '',
        prefix: '', // 'USER';
        suffix: '', //'ASSISTANT';
    };

    handleSave() {
        Object.keys(this.parameter).forEach((key) => {
            this.model[key] = this.parameter[key];
        });
    }

    initParameter() {
        Object.keys(this.parameter).forEach((key) => {
            this.parameter[key] = this.model[key];
        });
    }

    mounted() {
        this.initParameter();
    }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import { importAll, ImportAllResult } from '@/utils';
import Cookies from 'js-cookie';

const routeModules: ImportAllResult[] = importAll(require.context('@/router/modules', false, /\.ts$/));
const routes = routeModules.reduce((finalRoutes: Array<any>, routerModule) => finalRoutes.concat(routerModule.module), []);
Vue.use(VueRouter);

let $router = new VueRouter({
    mode: 'history',
    base: '/mark',
    routes,
});

$router.beforeEach((to, from, next) => {
    console.log(Cookies.get('cmark_pub_platform'));

    if (to.path === '/login') {
        next();
    } else if (Cookies.get('cmark_pub_platform') == 2) {
        if (to.path === '/chat') {
            next();
        } else {
            next('/chat');
        }
    } else {
        if (to.path === '/chat') {
            next('/task-list');
        }
        next();
    }
});

window.$router = $router;
console.log('$router', $router, routes);
export default $router;
export { routes };

import AddDialog from './index.vue';

export default function () {
    const instance: AddDialog & { show: boolean } = new AddDialog();

    instance.$mount();
    instance.show = true;

    return new Promise<any[]>((resolve, reject) => {
        instance.$on('success', resolve);
        instance.$on('cancel', reject);
    });
}

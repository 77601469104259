





























































































































































































































































import { Component, Prop, Vue } from '@/decorators';
import { Message } from '@ss/mtd-vue';

export const EMPTY_SENTENCE = {
    utterance: '',
    list: [],
};
@Component
export default class UnderlineWordItem extends Vue {
    @Prop({ type: Boolean, default: true })
    allowDelete: boolean;
    @Prop({ type: Boolean, default: true })
    allWordDeal: boolean;
    @Prop({ type: String, default: 'edit' })
    mode: string;
    @Prop({ type: Object, default: { text: '' } })
    queryData: any;
    @Prop()
    selectOptions: any;
    @Prop({ type: Boolean, default: false })
    allowCross: boolean;
    @Prop({ type: Boolean, default: false })
    relationWord: boolean;
    @Prop()
    relationConfig: any;
    @Prop({ default: '' })
    annotationType: any;
    @Prop({ type: Boolean, default: false })
    allowEmpty: boolean;
    @Prop({ type: Boolean, default: false })
    rephraseMode: any;
    @Prop({ type: Boolean, default: false })
    readonly: boolean;

    collapse = [];

    popSelect: any = {
        type: '',
        left: '',
        top: '',
    };
    grammarList: any = { length: 0 };
    labelsListIds = 0;
    popInfo: any = { grammarIdx: '' };

    mounted() {
        document.body.addEventListener('click', this.hideSelectPop);
        this.addGrammar();
    }
    beforeDestroy() {
        this.$el.removeEventListener('click', this.hideSelectPop);
    }
    // 将chooseInfo下的idx -> id，兼容历史数据
    formatting(value) {
        return value.map((i) => {
            return { id: i.idx, text: i.text };
        });
    }
    getCrossGrammarText(query, idx) {
        const chooseInfo = this.grammarList[idx]['chooseInfo'];
        const arr = [];
        for (let i = 0; i < Array.from(query).length; i++) {
            let html = query[i];
            chooseInfo?.forEach((item) => {
                if (item.start < i && item.end - 1 > i) {
                    html = `<span class="char-level-${item.collideLevel - 1}" labels-id="${item.labelsId}">${html}</span>`;
                } else if (item.start === i) {
                    html = `<span class="char-level-${item.collideLevel - 1} level-start-${item.collideLevel - 1}" labels-id="${
                        item.labelsId
                    }">${html}</span>`;
                } else if (item.end - 1 === i) {
                    html = `<span class="char-level-${item.collideLevel - 1} level-end-${item.collideLevel - 1}" labels-id="${
                        item.labelsId
                    }">${html}</span>`;
                }
            });
            arr.push(html);
        }
        return arr.join('');
    }

    sortedChooseInfo(chooseInfo) {
        return chooseInfo?.concat([]).sort((a, b) => {
            if (a.idx === b.idx) {
                return a.text.length > b.text.length ? 1 : -1;
            } else {
                return a.idx > b.idx ? 1 : -1;
            }
        });
    }
    // 关闭 pop
    hideSelectPop() {
        this.popSelect.type = '';
        const $mouseSelect = this.$el.querySelector('.mouse-select');
        if ($mouseSelect) {
            ($mouseSelect as any).outerHTML = ($mouseSelect as any).innerText;
        }
    }
    selectLabels(e: any, item: any, idx: number) {
        if (this.mode === 'show') {
            return;
        }
        let info = (window.getSelection() as any).getRangeAt(0);
        this.hideSelectPop();
        if (info.startOffset < info.endOffset && info.startContainer === info.endContainer) {
            let span = document.createElement('span');
            span.setAttribute('class', 'mouse-select');
            info.surroundContents(span);
            let position = span.getBoundingClientRect();
            this.popSelect.left = (position as any).x + position.width / 2 + 'px';
            this.popSelect.top = (position as any).y;
            this.popInfo.grammarIdx = idx;
            this.changeSelectPop('add');
        } else if (info.startContainer !== info.endContainer) {
            item.tipShow = true;
            setTimeout(() => {
                item.tipShow = false;
            }, 3000);
        }
    }
    addChoose() {
        this.chooseLabels();
        this.changeSelectPop('');
        if (this.grammarList?.[0]?.chooseInfo?.length) {
            this.collapse.push(this.grammarList[0]?.chooseInfo.length - 1);
        }
    }
    getHaveBrCurStart(cur, parent) {
        // 当前所在节点在父节点list中的idx
        let curIndex = Array.from(parent).indexOf(cur);
        // 划词在当前节点内的start
        let len = Array.from(cur.previousSibling.textContent).length;
        for (let i = 0; i < curIndex - 1; i++) {
            // 5是<br/>标签的长度
            // len += i % 2 == 1 ? 5 : parent[i].textContent.length;
            // dom元素节点 当前只支持<br>换行 <br />在浏览器中表现为<br>、建议用户只上传<br>
            if (parent[i].nodeType === 1) {
                if (parent[i].nodeName === 'BR') {
                    len += parent[i].outerHTML.length;
                } else {
                    Message.warning('标注格式错误,请联系工作人员!');
                }
            }
            // Element 或者 Attr 中实际的文字
            if (parent[i].nodeType === 3) {
                len += parent[i].textContent.length;
            }
        }
        return len;
    }
    getHightLabelStart(cur) {
        if (!cur.previousSibling) return 0;
        // 遍历得当前节点之前的所有文本长度
        let getCurIdx = (num, list) => {
            let res = 0;
            for (let i = 0; i < num - 1; i++) {
                res += list.childNodes[i].textContent.length;
            }
            return res;
        };

        // 当前层级内的start
        let start = Array.from(cur.previousSibling.textContent).length;
        // 所划词在整个query NodeList中的idx
        let curIndex = Array.from(cur.parentNode.childNodes).indexOf(cur);
        start += getCurIdx(curIndex, cur.parentNode);

        // 对高亮（<span>）内再进行一次计算
        if (cur.parentNode.nodeName !== 'P') start += this.getHightLabelStart(cur.parentNode);

        return start;
    }
    chooseLabels() {
        if (!this.allowCross) {
            const $mouseSelect: any = this.$el.querySelector('.mouse-select');
            let idx: any = this.getHightLabelStart($mouseSelect);
            const $parentClone: any = $mouseSelect.parentNode.cloneNode(true);
            const $mouseSelectClone: any = $parentClone.querySelector('.mouse-select');
            $parentClone.querySelectorAll('.select-active').forEach(($dom: any) => {
                $dom.outerHTML = $dom.innerText;
            });
            const $nodeName: any = $mouseSelect.parentNode.nodeName;
            const chooseInfo = this.grammarList[this.popInfo.grammarIdx]['chooseInfo'];
            const newObj = {
                labelsId: this.labelsListIds,
                idx: idx,
                text: $mouseSelectClone.innerText,
                data: [],
            };
            if (this.rephraseMode) this.$set(newObj, 'rephraseData', '');
            const isPush = chooseInfo.some((item, idx) => {
                if (idx < item.idx) {
                    chooseInfo.splice(idx, 0, newObj);
                    return true;
                }
                return false;
            });
            if (!isPush) {
                chooseInfo.push(newObj);
            }
            $mouseSelect.setAttribute('class', 'select-active');
            $mouseSelect.setAttribute('labels-id', this.labelsListIds);
            ++this.labelsListIds;
        } else {
            const $mouseSelect: HTMLElement = this.$el.querySelector('.mouse-select');
            const chooseInfo = this.grammarList[this.popInfo.grammarIdx]['chooseInfo'];
            let start = Array.from($mouseSelect.previousSibling.textContent).length;
            const text = $mouseSelect.innerText;
            // 如内含html标签，需要对query进行遍历获得准确的start、end
            start = this.getHaveBrCurStart($mouseSelect, $mouseSelect.parentNode.childNodes);
            const end = start + Array.from(text).length;
            let collideMaxLevel = 0; //最大碰撞层级
            chooseInfo.forEach((item) => {
                if (
                    (item.start < start && item.end > start) ||
                    (item.start < end && item.end > end) ||
                    (start < item.start && end > item.start) ||
                    (start < item.end && end > item.end)
                ) {
                    //collide
                    if (collideMaxLevel < item.collideLevel) {
                        collideMaxLevel = item.collideLevel;
                    }
                }
                if (item.start === start && item.end === end) {
                    if (collideMaxLevel < item.collideLevel) {
                        collideMaxLevel = item.collideLevel;
                    }
                }
            });
            const newObj = {
                labelsId: this.labelsListIds,
                idx: start,
                text,
                end,
                start,
                collideLevel: collideMaxLevel + 1,
                data: [],
            };
            chooseInfo.push(newObj);
            ++this.labelsListIds;
        }
    }
    changeSelectPop(type: string) {
        if (type === '') {
            this.hideSelectPop();
        } else {
            this.popSelect.type = type;
        }
    }
    deleteChoose(item, _choose) {
        var $selectActive: any = this.$el.querySelector(`[labels-id="${_choose.labelsId}"]`);
        $selectActive.outerHTML = $selectActive.innerText;
        item.chooseInfo.some((choose, idx) => {
            if (_choose.labelsId === choose.labelsId) {
                item.chooseInfo.splice(idx, 1);
                return true;
            }
            return false;
        });
    }
    getAnnotationResult() {
        if (Object.keys(this.grammarList).length <= 1 && !this.allowEmpty) {
            this.$mtd.message.error(`您还没有做划词的标注`);
            return false;
        }
        for (let i in this.grammarList) {
            if (['length', 'paths', 'cityAnnotation'].includes(i)) continue;
            let chooseInfo = this.sortedChooseInfo(this.grammarList[i].chooseInfo);
            let _idx = 0;
            for (let j = 0; j < chooseInfo.length; j++) {
                if (_idx !== chooseInfo[j]['idx'] && this.allWordDeal === true) {
                    this.$mtd.message.error(`【ID：${i}】的划词没有标注完全`);
                    return false;
                }
                if (!chooseInfo[j]['data'].length) {
                    this.$mtd.message.error(`【ID：${i}】的划词：${chooseInfo[j].text} 还没有完成标注`);
                    return false;
                }
                // if (!chooseInfo[j]['rephraseData']) {
                //     this.$mtd.message.error(`【ID：${i}】的划词：${chooseInfo[j].text} 还没有完成改写`);
                //     return false;
                // }
                _idx += Array.from(chooseInfo[j].text).length;
                if (this.$refs['cascader' + i + j]?.[0]) {
                    const nodes = this.$refs['cascader' + i + j][0].getCheckedNodes();
                    if (nodes && nodes[0] && nodes[0]['pathLabels'] && nodes[0]['pathLabels'].length) {
                        chooseInfo[j]['labels'] = nodes[0]['pathLabels'].join('-');
                    }
                }
            }
            this.grammarList[i].textHtml = this.$el.querySelector(`[grammarid="${i}"]`).innerHTML;
        }
        return JSON.parse(JSON.stringify(this.grammarList));
    }
    addGrammar(data?) {
        if (!data && !this.relationWord) {
            data = {
                id: this.grammarList.length,
                query: this.queryData.query,
                tipShow: false,
                chooseInfo: [],
            };
        }
        if (!data && this.relationWord) {
            data = {
                id: this.grammarList.length,
                query: this.queryData.query,
                tipShow: false,
                chooseInfo: [],
                relationship: [],
                relationshipBackup: [],
            };
        }
        this.$set(this.grammarList, this.grammarList.length, data);
        ++this.grammarList.length;
    }
    deleteGrammar(idx) {
        this.$delete(this.grammarList, idx);
        this.grammarList.length = this.grammarList.length - 1;
    }
    cleanInfo() {
        this.grammarList = {
            length: 0,
        };
        this.labelsListIds = 0;
        this.popInfo = {
            grammarIdx: '',
        };
    }
    setResult(data?, text?) {
        if (!data) {
            this.addGrammar();
        } else {
            for (let index in data) {
                data[index] = {
                    labelsId: data[index].labelsId,
                    idx: data[index].idx,
                    text: data[index].text,
                    data: data[index].data,
                    rephraseData: data[index].rephraseData,
                    reason: data[index].reason,
                };
            }
            this.grammarList = {
                0: {
                    chooseInfo: data,
                    id: 0,
                    query: this.queryData.query,
                    tipShow: false,
                },
                length: 1,
            };
            this.$nextTick(() => {
                for (let i in this.grammarList) {
                    if (['length', 'paths', 'cityAnnotation'].includes(i)) continue;
                    let chooseInfo = this.grammarList[i].chooseInfo || [];
                    for (let j = 0; j < chooseInfo.length; j++) {
                        if (chooseInfo[j]['labelsId'] >= this.labelsListIds) {
                            this.labelsListIds = chooseInfo[j]['labelsId'] + 1;
                        }
                    }
                    //兼容2021.9.2修复bug导致的另一个bug
                    let query = text;
                    if (!query) {
                        query = this.queryData.query;
                    }
                    this.$el.querySelector(`[grammarid="${i}"]`).innerHTML = query;
                }
            });
        }
    }
}

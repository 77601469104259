















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

interface IRectangleShape {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    currentColor: string;
    type: string;
    x: number;
    y: number;
    textX: number;
    textY: number;
    text: string;
}

@Component
export default class ImgAnnotation extends Vue {
    @Prop() img!: string;
    @Prop() width!: number;
    @Prop() height!: number;
    @Prop() useDefaultContentData!: boolean;
    @Prop() tool!: string[];
    @Prop() historyData: any;
    @Prop() readonly?: boolean;

    @Ref('canvas') canvasRef!: HTMLCanvasElement;

    defaultImg = '//s3plus.meituan.net/v1/mss_c9d411db85ec4b59aaebc4dfca416c55/production/search-nlp-fe/static/img/clogo.b681817.png';

    canvasImg: HTMLImageElement | null = null;
    currentTool = '';
    currentShape: Partial<IRectangleShape> | null = null;
    currentColor = '#ff0000';
    ctx: CanvasRenderingContext2D | null = null;
    shapes: Partial<IRectangleShape>[] = [];

    toolMap = Object.freeze({
        rectangle: '画框',
        point: '打点',
    });

    info = ['rectangle', 'point'];
    historyCollapse = [];

    rectangleTags = [];
    pointTags = [];

    createRectangle(e: MouseEvent) {
        const startX = e.clientX - this.canvasRef.getBoundingClientRect().left;
        const startY = e.clientY - this.canvasRef.getBoundingClientRect().top;

        this.currentShape = {
            startX,
            startY,
            endX: startX,
            endY: startY,
            currentColor: this.currentColor,
            type: this.currentTool,
            textX: startX,
            textY: startY,
            text: `框${this.shapes.filter((s) => s.type === 'rectangle').length + 1}`,
        };
        this.shapes.push(this.currentShape);

        this.canvasRef.addEventListener('mousemove', this.canvasMouseMove);
        document.addEventListener('mouseup', this.canvasMouseUp);
    }

    createPoint(e: MouseEvent) {
        this.currentShape = {
            x: e.offsetX,
            y: e.offsetY,
            type: this.currentTool,
            currentColor: this.currentColor,
            textX: e.offsetX - 8,
            textY: e.offsetY - 4,
            text: `点${this.shapes.filter((s) => s.type === 'point').length + 1}`,
        };
        this.shapes.push(this.currentShape);
        this.drawCanvas();
        this.canvasRef.addEventListener('mousemove', this.canvasMouseMove);
        document.addEventListener('mouseup', this.canvasMouseUp);
    }

    offsetStartX = 0;
    offsetStartY = 0;
    offsetEndX = 0;
    offsetEndY = 0;
    moveItem(e: MouseEvent) {
        const startX = e.clientX - this.canvasRef.getBoundingClientRect().left;
        const startY = e.clientY - this.canvasRef.getBoundingClientRect().top;
        this.currentShape = this.shapes.find((shape) => this.isInsideRectangle(startX, startY, shape));
        if (this.currentShape) {
            this.offsetStartX = startX - this.currentShape.startX;
            this.offsetStartY = startY - this.currentShape.startY;
            this.offsetEndX = this.currentShape.endX - this.currentShape.startX - this.offsetStartX;
            this.offsetEndY = this.currentShape.endY - this.currentShape.startY - this.offsetStartY;
            this.canvasRef.addEventListener('mousemove', this.canvasMouseMove);
        }
        document.addEventListener('mouseup', this.canvasMouseUp);
    }

    zoomParams: string[][] = [];
    handleZoom(e: MouseEvent) {
        if (this.currentShape) {
            const x = e.clientX - this.canvasRef.getBoundingClientRect().left;
            const y = e.clientY - this.canvasRef.getBoundingClientRect().top;
            const { startX, endX, startY, endY } = this.currentShape;
            const startXRadius = [startX, startX - 1, startX + 1];
            const startYRadius = [startY, startY - 1, startY + 1];
            const endXRadius = [endX, endX - 1, endX + 1];
            const endYRadius = [endY, endY - 1, endY + 1];
            if (this.rectangleDrawDirection === 'leftTopToRightBottom') {
                // 起点在左上角 终点在右下角
                if (this.calcTwoPointDistance(x, y, startX, startY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (startXRadius.includes(x)) {
                    this.zoomParams = [['startX', 'x']];
                } else if (endXRadius.includes(x)) {
                    this.zoomParams = [['endX', 'x']];
                } else if (startYRadius.includes(y)) {
                    this.zoomParams = [['startY', 'y']];
                } else if (endYRadius.includes(y)) {
                    this.zoomParams = [['endY', 'y']];
                }
            } else if (this.rectangleDrawDirection === 'rightTopToLeftBottom') {
                // 起点在右上角 终点在左下角
                if (this.calcTwoPointDistance(x, y, endX, startY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (startXRadius.includes(x)) {
                    this.zoomParams = [['startX', 'x']];
                } else if (endXRadius.includes(x)) {
                    this.zoomParams = [['endX', 'x']];
                } else if (startYRadius.includes(y)) {
                    this.zoomParams = [['startY', 'y']];
                } else if (endYRadius.includes(y)) {
                    this.zoomParams = [['endY', 'y']];
                }
            } else if (this.rectangleDrawDirection === 'leftBottomToRightTop') {
                // 起点在左下角 终点在右上角
                if (this.calcTwoPointDistance(x, y, startX, endY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (startXRadius.includes(x)) {
                    this.zoomParams = [['startX', 'x']];
                } else if (endXRadius.includes(x)) {
                    this.zoomParams = [['endX', 'x']];
                } else if (startYRadius.includes(y)) {
                    this.zoomParams = [['startY', 'y']];
                } else if (endYRadius.includes(y)) {
                    this.zoomParams = [['endY', 'y']];
                }
            } else if (this.rectangleDrawDirection === 'rightBottomToLeftTop') {
                // 起点在右下角 终点在左上角
                if (this.calcTwoPointDistance(x, y, endX, endY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['endY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                    this.zoomParams = [
                        ['endX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                    this.zoomParams = [
                        ['startX', 'x'],
                        ['startY', 'y'],
                    ];
                } else if (startXRadius.includes(x)) {
                    this.zoomParams = [['startX', 'x']];
                } else if (endXRadius.includes(x)) {
                    this.zoomParams = [['endY', 'x']];
                } else if (startYRadius.includes(y)) {
                    this.zoomParams = [['startY', 'y']];
                } else if (endYRadius.includes(y)) {
                    this.zoomParams = [['endY', 'y']];
                }
            }
            this.canvasRef.addEventListener('mousemove', this.canvasMouseMove);
            document.addEventListener('mouseup', this.canvasMouseUp);
        }
    }

    changeZoom(e: MouseEvent) {
        const x = e.clientX - this.canvasRef.getBoundingClientRect().left;
        const y = e.clientY - this.canvasRef.getBoundingClientRect().top;
        this.zoomParams.forEach((z) => {
            this.currentShape[z[0]] = z[1] === 'x' ? x : y;
        });
        this.currentShape.textX = this.currentShape.startX < this.currentShape.endX ? this.currentShape.startX : this.currentShape.endX;
        this.currentShape.textY = this.currentShape.startY < this.currentShape.endY ? this.currentShape.startY : this.currentShape.endY;
        this.drawCanvas();
    }

    isMouseDown = false;
    canvasMouseDown(e: MouseEvent) {
        this.isMouseDown = true;
        switch (this.currentTool) {
            case 'rectangle':
                this.createRectangle(e);
                break;
            case 'point':
                this.createPoint(e);
                break;
            case 'move':
                this.mouseStyle.cursor = 'move';
                this.moveItem(e);
                break;
            case 'zoom':
                this.handleZoom(e);
                break;
            default:
                break;
        }
    }

    canvasMouseMove(e: MouseEvent) {
        if (!this.currentShape) {
            return false;
        }
        switch (this.currentTool) {
            case 'rectangle':
                this.currentShape.endX = e.clientX - this.canvasRef.getBoundingClientRect().left;
                this.currentShape.endY = e.clientY - this.canvasRef.getBoundingClientRect().top;
                // eslint-disable-next-line no-case-declarations
                const { startX, startY, endX, endY } = this.currentShape;
                this.currentShape.textX = startX < endX ? startX : endX;
                this.currentShape.textY = startY < endY ? startY : endY;
                this.drawCanvas();
                break;
            case 'point':
                this.currentShape.x = e.offsetX;
                this.currentShape.y = e.offsetY;
                this.currentShape.textX = e.offsetX - 8;
                this.currentShape.textY = e.offsetY - 4;
                this.drawCanvas();
                break;
            case 'move':
                if (this.currentShape.type === 'rectangle') {
                    this.currentShape.startX = e.clientX - this.canvasRef.getBoundingClientRect().left - this.offsetStartX;
                    this.currentShape.startY = e.clientY - this.canvasRef.getBoundingClientRect().top - this.offsetStartY;
                    this.currentShape.endX = e.clientX - this.canvasRef.getBoundingClientRect().left + this.offsetEndX;
                    this.currentShape.endY = e.clientY - this.canvasRef.getBoundingClientRect().top + this.offsetEndY;
                    const { startX, startY, endX, endY } = this.currentShape;
                    this.currentShape.textX = startX < endX ? startX : endX;
                    this.currentShape.textY = startY < endY ? startY : endY;
                } else if (this.currentShape.type === 'point') {
                    this.currentShape.x = e.offsetX + 2;
                    this.currentShape.y = e.offsetY + 2;
                    this.currentShape.textX = e.offsetX - 6;
                    this.currentShape.textY = e.offsetY - 2;
                }
                this.drawCanvas();
                break;
            case 'zoom':
                this.changeZoom(e);

                break;
            default:
                break;
        }
    }

    canvasMouseUp() {
        this.isMouseDown = false;
        switch (this.currentTool) {
            case 'rectangle':
                if (this.currentShape) {
                    const { startX, startY, endX, endY } = this.currentShape;
                    if (startX === endX && startY === endY) {
                        this.shapes.pop();
                    } else {
                        this.rectangleTags.push({
                            label: `框${this.shapes.filter((shape) => shape.type === 'rectangle').length}`,
                            value: '',
                        });
                    }
                }
                this.clearMouse();
                break;
            case 'point':
                this.pointTags.push({
                    label: `点${this.shapes.filter((shape) => shape.type === 'point').length}`,
                    value: '',
                });
                this.clearMouse();
                break;
            case 'move':
                this.clearMouse();
                break;
            case 'zoom':
                this.clearMouse();
                break;

            default:
                break;
        }
    }

    clearMouse() {
        this.currentShape = null;
        this.canvasRef.removeEventListener('mousemove', this.canvasMouseMove);
        document.removeEventListener('mouseup', this.canvasMouseUp);
    }

    drawCanvas() {
        this.initCanvas();
        this.shapes.forEach((shape) => {
            this.ctx.strokeStyle = shape.currentColor;
            this.ctx.fillStyle = shape.currentColor;
            switch (shape.type) {
                case 'rectangle':
                    this.ctx.lineWidth = 2;
                    this.ctx.beginPath();
                    this.ctx.rect(shape.startX, shape.startY, shape.endX - shape.startX, shape.endY - shape.startY);
                    this.ctx.stroke();
                    break;
                case 'point':
                    this.ctx.beginPath();
                    this.ctx.arc(shape.x - 2, shape.y - 2, 2, 0, 2 * Math.PI);
                    this.ctx.fillStyle = shape.currentColor;
                    this.ctx.fill();
                    break;
                default:
                    break;
            }

            this.ctx.fillText(shape.text, shape.textX, shape.textY - 4);
        });
    }

    isInsideRectangle(x: number, y: number, rect: Partial<IRectangleShape>) {
        switch (rect.type) {
            case 'rectangle':
                return (
                    (x > rect.startX && x < rect.endX && y > rect.startY && y < rect.endY) || // 起点坐标在左上角，终点坐标在右下角
                    (x < rect.startX && x > rect.endX && y < rect.startY && y > rect.endY) || // 起点坐标在右下角，终点坐标在左上角
                    (x > rect.startX && x < rect.endX && y < rect.startY && y > rect.endY) || // 起点坐标在左下角，终点坐标在右上角
                    (x < rect.startX && x > rect.endX && y > rect.startY && y < rect.endY) // 起点坐标在右上角，终点坐标在左下角
                );
            case 'point':
                return this.calcTwoPointDistance(x, y, rect.x, rect.y);

            default:
                break;
        }
    }

    initCanvas() {
        this.ctx.clearRect(0, 0, this.canvasImg.width, this.canvasImg.height);
        this.ctx.drawImage(this.canvasImg, 0, 0, this.canvasImg.width, this.canvasImg.height);
    }

    handleReset() {
        this.$mtd
            .confirm({
                title: '提示',
                message: '此操作将清空已标注的内容，确定继续吗？',
                width: '430px',
                type: 'warning',
                okButtonText: '确定',
                showCancelButton: true,
            })
            .then(() => {
                this.initCanvas();
                this.shapes = [];
                this.rectangleTags = [];
                this.pointTags = [];
            })
            .catch(() => {
                //
            });
    }

    handleDelTag(type, tag, index, tags) {
        this.$mtd
            .confirm({
                title: '提示',
                message: `该操作将一并删除图内已标注的${tag.label}，确定删除吗？`,
                width: '430px',
                type: 'warning',
                okButtonText: '确定',
                showCancelButton: true,
            })
            .then(() => {
                const shape = this.shapes.filter((shape) => shape.type === type)[index];
                const shapeIndex = this.shapes.findIndex((s) => s === shape);
                tags.splice(index, 1);
                this.shapes.splice(shapeIndex, 1);
                const text = type === 'rectangle' ? '框' : '点';
                this.shapes
                    .filter((shape) => shape.type === type)
                    .forEach((shape, i) => {
                        shape.text = `${text}${i + 1}`;
                        tags[i].label = `${text}${i + 1}`;
                    });
                this.drawCanvas();
            })
            .catch(() => {
                //
            });
    }

    handleChangeTool(val) {
        this.canvasRef.removeEventListener('mousemove', this.tagMove);
        this.canvasRef.removeEventListener('mousemove', this.rectangleZoom);
        switch (val) {
            case 'rectangle':
                this.mouseStyle.cursor = 'crosshair';
                break;
            case 'zoom':
                this.canvasRef.addEventListener('mousemove', this.rectangleZoom);
                break;
            case 'move':
                this.canvasRef.addEventListener('mousemove', this.tagMove);
                break;

            default:
                this.mouseStyle.cursor = 'default';
                break;
        }
    }

    // 处理鼠标样式
    rectangleZoom(e) {
        if (this.isMouseDown && this.currentShape) {
            return false;
        }
        const x = e.clientX - this.canvasRef.getBoundingClientRect().left;
        const y = e.clientY - this.canvasRef.getBoundingClientRect().top;
        this.currentShape = this.shapes
            .filter((shape) => shape.type === 'rectangle')
            .find((shape) => this.isInsideRectangleBorder(x, y, shape));
    }

    tagMove(e: MouseEvent) {
        const startX = e.clientX - this.canvasRef.getBoundingClientRect().left;
        const startY = e.clientY - this.canvasRef.getBoundingClientRect().top;
        const res = this.shapes.find((shape) => this.isInsideRectangle(startX, startY, shape));
        this.mouseStyle.cursor = res ? 'move' : 'default';
    }

    mouseStyle = {
        cursor: 'crosshair',
    };
    rectangleDrawDirection: 'leftTopToRightBottom' | 'rightTopToLeftBottom' | 'leftBottomToRightTop' | 'rightBottomToLeftTop' | '' = '';
    isInsideRectangleBorder(x: number, y: number, rect: Partial<IRectangleShape>) {
        const { startX, endX, startY, endY } = rect;
        const startXRadius = [startX, startX - 1, startX + 1];
        const startYRadius = [startY, startY - 1, startY + 1];
        const endXRadius = [endX, endX - 1, endX + 1];
        const endYRadius = [endY, endY - 1, endY + 1];
        // 起点在左上角 终点在右下角 startX < endX  startY > endY
        // 起点在右上角 终点在左下角 startX > endX  startY < endY
        // 起点在左下角 终点在右上角 startX < endX  startY > endY
        // 起点在右下角 终点在左上角 startX > endX  startY > endY
        if (x >= startX && x <= endX && y >= startY && y <= endY) {
            this.rectangleDrawDirection = 'leftTopToRightBottom';
            // 起点在左上角 终点在右下角
            if (this.calcTwoPointDistance(x, y, startX, startY)) {
                this.mouseStyle.cursor = 'nw-resize'; // 左上角
            } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                this.mouseStyle.cursor = 'ne-resize'; // 右上角
            } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                this.mouseStyle.cursor = 'sw-resize'; // 左下角
            } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                this.mouseStyle.cursor = 'se-resize'; // 右下角
            } else if (startXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (endXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (startYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else if (endYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else {
                this.mouseStyle.cursor = 'default';
                return false;
            }
        } else if (x < startX && x > endX && y > startY && y < endY) {
            // 起点在右上角 终点在左下角
            this.rectangleDrawDirection = 'rightTopToLeftBottom';
            if (this.calcTwoPointDistance(x, y, endX, startY)) {
                this.mouseStyle.cursor = 'nw-resize'; // 左上角
            } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                this.mouseStyle.cursor = 'ne-resize'; // 右上角
            } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                this.mouseStyle.cursor = 'sw-resize'; // 左下角
            } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                this.mouseStyle.cursor = 'se-resize'; // 右下角
            } else if (startXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (endXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (startYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else if (endYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else {
                this.mouseStyle.cursor = 'default';
                return false;
            }
        } else if (x > startX && x < endX && y < startY && y > endY) {
            // 起点在左下角 终点在右上角
            this.rectangleDrawDirection = 'leftBottomToRightTop';
            if (this.calcTwoPointDistance(x, y, startX, endY)) {
                this.mouseStyle.cursor = 'nw-resize'; // 左上角
            } else if (this.calcTwoPointDistance(x, y, endX, endY)) {
                this.mouseStyle.cursor = 'ne-resize'; // 右上角
            } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                this.mouseStyle.cursor = 'sw-resize'; // 左下角
            } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                this.mouseStyle.cursor = 'se-resize'; // 右下角
            } else if (startXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (endXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (startYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else if (endYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else {
                this.mouseStyle.cursor = 'default';
                return false;
            }
        } else if (x < startX && x > endX && y < startY && y > endY) {
            // 起点在右下角 终点在左上角
            this.rectangleDrawDirection = 'rightBottomToLeftTop';
            if (this.calcTwoPointDistance(x, y, endX, endY)) {
                this.mouseStyle.cursor = 'nw-resize'; // 左上角
            } else if (this.calcTwoPointDistance(x, y, startX, endY)) {
                this.mouseStyle.cursor = 'ne-resize'; // 右上角
            } else if (this.calcTwoPointDistance(x, y, endX, startY)) {
                this.mouseStyle.cursor = 'sw-resize'; // 左下角
            } else if (this.calcTwoPointDistance(x, y, startX, startY)) {
                this.mouseStyle.cursor = 'se-resize'; // 右下角
            } else if (startXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (endXRadius.includes(x)) {
                this.mouseStyle.cursor = 'ew-resize';
            } else if (startYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else if (endYRadius.includes(y)) {
                this.mouseStyle.cursor = 'ns-resize';
            } else {
                this.mouseStyle.cursor = 'default';
                return false;
            }
        } else {
            this.mouseStyle.cursor = 'default';
            return false;
        }
        return true;
    }

    calcTwoPointDistance(x2: number, y2: number, x1: number, y1: number) {
        const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) - 2;
        return distance <= 4;
    }

    canvasMouseleave() {
        this.clearMouse();
    }

    init() {
        this.ctx = this.canvasRef.getContext('2d');
        this.ctx.font = '14px Arial';
        this.canvasImg = new Image();
        this.canvasImg.width = this.width;
        this.canvasImg.height = this.height;
        this.canvasImg.src = this.useDefaultContentData ? this.defaultImg : this.img;
        this.canvasImg.onload = () => {
            this.canvasRef.width = this.useDefaultContentData ? 200 : this.canvasImg.width;
            this.canvasRef.height = this.useDefaultContentData ? 200 : this.canvasImg.height;
            this.initCanvas();
            if (this.tool.length) {
                this.currentTool = this.tool[0];
            }
            if (this.readonly) {
                this.currentTool = '';
                this.mouseStyle.cursor = 'default';
            }
            if (this.historyData) {
                const { shapes, pointTags, rectangleTags, history } = this.historyData;
                this.shapes = shapes;
                this.pointTags = pointTags;
                this.rectangleTags = rectangleTags;
                this.drawCanvas();
            }
        };
        this.canvasRef.addEventListener('mousedown', this.canvasMouseDown);
        this.canvasRef.addEventListener('mouseleave', this.canvasMouseleave);
        if (this.currentTool === 'rectangle') {
            this.mouseStyle.cursor = 'crosshair';
        }
    }

    transSaveData() {
        return {
            shapes: this.shapes,
            rectangleTags: this.rectangleTags,
            pointTags: this.pointTags,
        };
    }

    mounted() {
        this.init();
    }
}

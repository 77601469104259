const formItemSchema = {
    formItemType: 'el-cascader',
    label: '多级选择框',
    vModel: 'cascader',
    required: false,
    defaultValue: null,
    filterable: true,
    multiple: false,
    isMustSelectLeafNode: true,
    isModelCategory: false,
    annoCategoryContent: -1, // 用于存储默认选择的第1层children的id，这个初始值无意义，因为需要从后端请求过来为其赋值
    attr: {
        'no-data-text': '暂无数据',
        data: [
            {
                value: 'Felidae',
                label: '猫科',
                children: [
                    {
                        value: 'Panthera',
                        label: '豹属',
                        children: [
                            {
                                value: 'tiger',
                                label: '老虎',
                            },
                            {
                                value: 'lion',
                                label: '狮子',
                            },
                            {
                                value: 'leopard',
                                label: '豹子',
                            },
                            {
                                value: 'snowleopard',
                                label: '雪豹',
                            },
                        ],
                    },
                    {
                        value: 'Felis',
                        label: '猫属',
                        children: [
                            {
                                value: 'Domesticcat',
                                label: '家猫',
                            },
                            {
                                value: 'Wildcat',
                                label: '野猫',
                            },
                            {
                                value: 'sandcat',
                                label: '沙漠猫',
                            },
                            {
                                value: 'Mountaincat',
                                label: '草原猫',
                            },
                        ],
                    },
                ],
            },
            {
                value: 'Canidae',
                label: '犬科',
                children: [
                    {
                        value: 'Canis',
                        label: '犬属',
                        children: [
                            {
                                value: 'Wolf',
                                label: '狼',
                            },
                            {
                                value: 'Domesticdog',
                                label: '家犬',
                            },
                            {
                                value: 'Coyote',
                                label: '土狼',
                            },
                        ],
                    },
                    {
                        value: 'Vulpes',
                        label: '狐属',
                        children: [
                            {
                                value: 'redfox',
                                label: '红狐',
                            },
                            {
                                value: 'Fennecfox',
                                label: '沙狐',
                            },
                            {
                                value: 'Arcticfox',
                                label: '北极狐',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
const formItemConfig = {
    formStyle: {
        labelWidth: '100',
        size: 'mini',
        span: 20,
    },
    formItemList: [
        {
            formItemType: 'el-input',
            label: '名称',
            vModel: 'label',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '绑定值',
            tips: '区块内唯一Key值',
            vModel: 'vModel',
            required: true,
        },
        {
            formItemType: 'el-radio-group',
            label: '可搜索',
            vModel: 'filterable',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '可多选',
            vModel: 'multiple',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '是否必填',
            vModel: 'required',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '是否必须选择到最小叶子节点',
            vModel: 'isMustSelectLeafNode',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '是否是大模型类目标注',
            vModel: 'isModelCategory',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '请选择标注内容（展示类目体系下的首级类目）',
            vModel: 'annoCategoryContent',
            required: true,
            options: [],
        },
    ],
};
const formItemInfo = {
    name: '多级选择框',
};
export default { formItemSchema, formItemConfig, formItemInfo };






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

let jsonDemo = {
    text: '示例json',
};
@Component({
    components: {},
})
export default class JsonBox extends Vue {
    dialogVisible = false;
    activeName = 'format';
    jsonData: any = jsonDemo;
    @Prop({
        type: String,
        default: 'dialog', // dialog normal
    })
    mode: string;

    @Prop({
        type: String,
        default: undefined,
    })
    color: string;
    @Prop({
        type: String,
        default: undefined,
    })
    padding: string;
    @Prop({
        type: String,
        default: undefined,
    })
    fontSize: string;
    @Prop({
        type: String,
        default: undefined,
    })
    fontWeight: string;
    @Prop({
        type: Number,
        default: 2,
    })
    deep: number;

    @Prop({
        default: jsonDemo,
    })
    data: any;

    @Prop({
        type: String,
        default: undefined,
    })
    size: string;

    @Prop({
        type: [String, Number],
        default: '展示JSON',
    })
    text: string | number;

    safeParse(obj, field) {
        let value = obj[field];
        if (value === undefined) {
            return void 0;
        }
        let valueParse = value;
        // 防止空字符串和undefined
        if (value && typeof value === 'string') {
            try {
                valueParse = JSON.parse(value);
            } catch (e) {
                console.error(`${field}序列化失败:`, e);
            }
        }
        obj[field] = valueParse;
    }

    @Watch('data', { immediate: true })
    dataChange(val, oldVal) {
        // 防止
        Vue.nextTick(() => {
            let _jsonData: any = {};

            try {
                // 如果是字符串进行parse
                if (val && typeof val === 'string') {
                    _jsonData = JSON.parse(this.data);
                } else {
                    _jsonData = JSON.parse(JSON.stringify(this.data));
                }
            } catch (e) {
                //
            }

            // 针对业务的特殊parse逻辑
            this.safeParse(_jsonData, 'annotationResult');
            this.safeParse(_jsonData, 'annotationResultCheck');
            this.safeParse(_jsonData, 'extra');
            this.safeParse(_jsonData, 'suggestInfo');
            this.safeParse(_jsonData, 'config');
            this.safeParse(_jsonData, 'taskConfig');

            // 赋值到this上
            this.jsonData = _jsonData;
        });
    }
}

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import JsonBox from '@/components/json-box.vue';

@Component({
    components: { JsonBox },
})
export default class TaskTemp extends Vue {
    page = {
        total: 0,
        current: 1,
        size: 20,
    };
    caseList = [];
    selection = [];
    async mounted() {
        let { taskId } = this.$route.query;
        if (!this.$route.query.taskId) {
            // 有时候会跳两次
            this.$mtd.message.error('您还没有选择要标注的任务，请选择标注任务');
            Vue.nextTick(() => {
                this.$router.push('/task-list');
            });
            return void 0;
        } else {
            await this.listTempSaveCase(taskId, this.page.current, this.page.size);
        }
    }
    async listTempSaveCase(taskId, pageNum, pageSize) {
        let res = await this.$api.common.listTempSaveCase({ taskId: taskId, pageNum: pageNum, pageSize: pageSize });
        if (res.data) {
            let { totalSize, caseList } = res.data;
            this.page.total = totalSize;
            this.caseList = caseList;
        }
    }
    handleChange(current, size) {
        this.listTempSaveCase(this.$route.query.taskId, current, size);
    }
    async deleteAnnoCase(row) {
        let res = await this.$api.common.deleteAnnoCase({ caseId: row.caseId });
        if (res.rescode == 0) {
            this.$mtd.message.success('删除成功');
            this.listTempSaveCase(this.$route.query.taskId, this.page.current, this.page.size);
        } else {
            this.$mtd.message.error('删除失败');
        }
    }
    changeAnnotation(row) {
        this.$router.push({
            path: '/task-work',
            query: { taskType: this.$route.query.taskType, taskId: this.$route.query.taskId, caseId: row.caseId },
        });
    }
    async submitAllCase() {
        let params = {
            isAll: true,
            taskId: this.$route.query.taskId,
        };
        let res = await this.$api.common.submitAnnoCase(params);
        if (res.rescode === 0) {
            this.$mtd.message.success('提交成功');
            await this.listTempSaveCase(this.$route.query.taskId, this.page.current, this.page.size);
        }
    }

    async submitSelectCase() {
        let caseIdList = this.selection.map((i) => i.caseId);
        if (!caseIdList.length) {
            this.$mtd.message.error('没有选择任何数据');
            return void 0;
        }
        let params = {
            isAll: false,
            taskId: this.$route.query.taskId,
            caseIdList: this.selection.map((i) => i.caseId),
        };
        let res = await this.$api.common.submitAnnoCase(params);
        if (res.rescode === 0) {
            this.$mtd.message.success('提交成功');
            await this.listTempSaveCase(this.$route.query.taskId, this.page.current, this.page.size);
        }
    }
}

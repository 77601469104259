



























import { Vue, Component } from 'vue-property-decorator';
@Component
export default class AddDialog extends Vue {
    show = false;
    content = '';
    role = 'AGENT';

    handleSubmit() {
        if (!this.content.trim()) {
            this.$message.warning('请输入内容！');
            return false;
        }
        this.$emit('success', {
            role: this.role,
            content: this.content,
        });
        this.show = false;
    }

    handleCancel() {
        this.show = false;
        this.$emit('cancel');
    }

    handleClosed() {
        this.$destroy();
    }
}

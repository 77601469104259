import { isProd } from './env';

const ssoIdKey = 'cloudsearch_ssoid';

// https://km.sankuai.com/page/70099265
import Cookies from 'js-cookie';
import SSOWeb from '@mtfe/sso-web';

const ssoWeb = SSOWeb({
    clientId: 'cloudsearch',
    accessEnv: isProd ? 'product' : 'test',
});
const initialization = ssoWeb.init();

export function login() {
    // return new Promise<void>((resolve, reject) => {
    //     ssoWeb.login().then((ssoid) => {
    //         if (ssoid && typeof ssoid === 'string') {
    //             Cookies.set(ssoIdKey, ssoid, { expires: 7, path: '/' });
    //             resolve();
    //         } else if (typeof ssoid === 'object') {
    //             let prefix = 'redirect to ';
    //             if (ssoid && ssoid.message && ssoid.message.startsWith(prefix)) {
    //                 // 此处会进行转跳
    //             }
    //             console.log('sso 登陆未直接成功:', ssoid);
    //         } else {
    //             alert(ssoid);
    //         }
    //     });
    // });
}

export function logout() {
    // return ssoWeb.logout().then(() => {
    //     window.location.href = ssoWeb.getLoginUrl();
    // });
}

export default { login, logout };





































































































































import { Vue, Component } from '@/decorators';
import { categoryList } from '../../helper/constant';
import { CaseDatas } from '@/model/modules/admin';
import simulator from './simulator.vue';

@Component({
    components: { simulator },
})
export default class TplMtMchatAnnotation extends Vue {
    categoryList = categoryList;
    $refs: { qrcode: any };
    remarkStr = '';

    loading: any = false;
    baseInfo: any = [];
    form: any = [];
    caseRelateLabel: any = {};
    modelArr: any = [];
    collapseActiveValue: any = '1';

    // 标注状态
    annotationResult: any = {
        result: '',
        reason: '',
    };

    suggestInfo: any = null;
    reasonStr = ''; // 不通过的标注理由
    caseData: CaseDatas = null;
    saveBtnLoading = false;
    user = localStorage.getItem('User_Info');
    caseInfo: any = {
        query: '',
    };
    config: any = {};

    async mounted() {
        this.initState();
        let { taskId, caseId } = this.$route.query;
        if (!taskId) {
            this.$mtd.message.error('您还没有选择要标注的任务，请先选择标注任务');
            Vue.nextTick(() => {
                this.$router.push('/task-list');
            });
            return void 0;
        }
        if (caseId) {
            await this.getCaseById(taskId, caseId);
        } else {
            await this.getNextCase(taskId, caseId);
        }
    }
    async getNextCase(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getNextCase({ taskId: taskId, currentCaseId: caseId || null });
        let data = res.data;
        if (data) {
            this.saveBtnLoading = false;
            this.caseData = data;
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    async getCaseById(taskId, caseId) {
        this.saveBtnLoading = true;
        let res = await this.$api.common.getCaseById({ caseId: caseId });
        this.saveBtnLoading = false;
        let data = res.data;
        if (data) {
            this.caseData = data;
            this.dealCaseData();
        } else {
            // this.$mtd.message.error('暂无数据');
        }
    }

    initState() {
        this.reasonStr = '';
        this.caseData = null;
        this.saveBtnLoading = true;
        this.annotationResult = {};
        this.form = [];
        this.baseInfo = [];
        this.config = {
            question: [],
            answer: [],
        };
    }

    dealCaseData() {
        let { annotationResult, caseInfo, config, suggestInfo } = this.caseData;
        this.caseInfo = caseInfo;
        this.getConfig(config);
        if (suggestInfo) {
            suggestInfo = JSON.parse(suggestInfo);
            suggestInfo.annotationResultCheck = JSON.parse(suggestInfo.annotationResultCheck);
            this.suggestInfo = suggestInfo;
        }
        // 如果存在标注数据，进行赋值操作，为了之后和质检的结果进行对比
        if (annotationResult) {
            this.annotationResult = JSON.parse(annotationResult);
            if (this.annotationResult.resultInfo) {
                this.form = this.annotationResult.resultInfo;
            }
            this.baseInfo = this.annotationResult.baseInfo;
        }
        this.initOther();
        // 首次标注需要初始化form
        if (this.baseInfo.length && !this.form.length) {
            this.initForm();
        }
    }
    initOther() {
        // case标注选项数组
        this.caseRelateLabel = this.config.caseAnnotate?.reduce((acc, cur) => {
            if (cur.needLabel) acc[cur.name] = cur.needLabel;
            return acc;
        }, {});
        // 调用模型集合数组
        this.modelArr = this.baseInfo[0]?.answer?.map((item) => item.displayName);
    }
    initForm() {
        // 首次标注初始化
        this.form = [];
        const len = this.baseInfo.length;
        for (let index = 0; index < len; index++) {
            this.form.push({});
            this.form[index]['question'] = this.baseInfo[index].question;
            for (let idx = 0; idx < this.modelArr.length; idx++) {
                this.$set(this.form[index], this.modelArr[idx], {
                    model: this.baseInfo[index].answer[idx].model,
                    content: this.baseInfo[index].answer[idx].content,
                    caseEvaluate: '',
                });
            }
        }
    }
    getConfig(config) {
        if (config && typeof config === 'string') {
            this.config = JSON.parse(config);
        } else if (config) {
            this.config = config;
        }
    }

    checkData() {
        let EvaluateNeedKey = this.config.caseAnnotate ? ['caseEvaluate'] : [];
        const len = this.baseInfo.length;

        if (!len) {
            this.$mtd.message.warning('请进行对话并结束对话后标注');
            return;
        }

        this.config.caseLabels?.forEach((item) => {
            EvaluateNeedKey.push(item.name);
        });

        let questionNeedKey = [];
        this.config.questionLabel?.forEach((item) => {
            questionNeedKey.push(item.name);
        });

        for (let index = 0; index < len; index++) {
            for (let key of questionNeedKey) {
                if (this.isEmpty(this.form[index][key])) {
                    this.$mtd.message.warning(`第${index + 1}轮中问题标注未填写完毕`);
                    return;
                }
            }
            for (let idx = 0; idx < this.modelArr.length; idx++) {
                for (let key of EvaluateNeedKey) {
                    if (this.isEmpty(this.form[index][this.modelArr[idx]][key])) {
                        this.$mtd.message.warning(`第${index + 1}轮中模型${this.modelArr[idx]}未填写完毕`);
                        return;
                    }
                }
                let caseEvaluateValue = this.form[index][this.modelArr[idx]].caseEvaluate;
                if (Object.keys(this.caseRelateLabel).includes(caseEvaluateValue)) {
                    for (let item of this.caseRelateLabel[caseEvaluateValue]) {
                        if (this.isEmpty(this.form[index][this.modelArr[idx]][item.name])) {
                            this.$mtd.message.warning(`第${index + 1}轮中模型${this.modelArr[idx]}未填写完毕`);
                            return;
                        }
                    }
                }
            }
        }
        this.annotationResult = {
            baseInfo: this.annotationResult.baseInfo,
            resultInfo: this.form,
        };
        return true;
    }

    async onTempSave() {
        if (!this.checkData()) {
            return void 0;
        }
        let { taskId, caseId, taskType } = this.$route.query;
        this.saveBtnLoading = true;
        try {
            let saveCaseInfo: any = {
                caseId: this.caseData.caseId,
                annotationResult: JSON.stringify(this.annotationResult),
            };
            let res = await this.$api.common.tempSaveCase(saveCaseInfo);
            if (res.rescode === 0) {
                this.saveBtnLoading = false;
                this.goNext(taskId, caseId, taskType);
                this.initState();
            }
        } catch (e) {
            console.log(e);
            this.saveBtnLoading = false;
        }
    }

    goNext(taskId, caseId, taskType) {
        if (caseId) {
            // 从列表过来的case
            this.$router.push({ path: '/task-temp', query: { taskType, taskId } });
        } else {
            // 正常标注的case
            this.getNextCase(this.caseData.taskId, this.caseData.caseId);
        }
    }

    async getPrev() {
        let res = await this.$api.common.getPreviousCase({ taskId: this.caseData.taskId, currentCaseId: this.caseData.caseId });
        let data = res.data;
        if (data) {
            this.caseData = data;
            this.dealCaseData();
        } else {
            this.initState();
        }
    }
    beforeDestroy() {
        this.initState();
    }
    async startAnnotation(message) {
        // 保存结果
        this.annotationResult = {
            baseInfo: message,
        };
        this.baseInfo = this.annotationResult.baseInfo;
        this.$nextTick(() => {
            this.dealCaseData();
            this.saveBtnLoading = false;
        });
    }
    curCaseAnnotation: any = '';
    caseStatusChange(event, index?, idx?) {
        if (this.curCaseAnnotation && this.caseRelateLabel[this.curCaseAnnotation]) {
            for (let item of this.caseRelateLabel[this.curCaseAnnotation]) {
                this.$set(this.form[index][this.modelArr[idx]], item.name, null);
            }
        }
        this.curCaseAnnotation = event;
    }
    isEmpty(v) {
        return v === void 0 || v === '' || v === null || (Array.isArray(v) && v.length === 0);
    }
}

// const fileNameRE = /^\.\/([^\s]+)\.ts$/; // ./xxx.ts

// export interface ImportAllResult {
//     moduleName: string;
//     camelModuleName: string;
//     module: any;
// }
// export function importAll(modulesContext: any): ImportAllResult[] {
//     return modulesContext.keys().map((modulesPath: string) => {
//         const moduleName = (modulesPath.match(fileNameRE) as RegExpMatchArray)[1];
//         return {
//             moduleName,
//             camelModuleName: moduleName.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : '')),
//             module: modulesContext(modulesPath).default,
//         };
//     });
// }
const defaultFileNameRE = /^\.\/([^\s]+)\.ts$/; // ./xxx.ts

export interface ImportAllResult {
    moduleName: string;
    camelModuleName: string;
    module: any;
}
export function importAll(modulesContext: any, fileNameRE = defaultFileNameRE): ImportAllResult[] {
    return modulesContext.keys().map((modulesPath: string) => {
        const moduleName = (modulesPath.match(fileNameRE) as RegExpMatchArray)[1];
        return {
            moduleName,
            camelModuleName: moduleName.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : '')),
            module: modulesContext(modulesPath).default,
        };
    });
}
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getListWorker, getChatGenerate } from '@/utils/multiRound';
import cloneDeep from 'lodash/cloneDeep';
import ModelDetail from './ModelDetail.vue';
import editContentDialog from './edit-content-dialog';
import DynamicForm from '@/components/dynamic-components/DynamicForm.vue';

interface IModel {
    model_id: number;
    temperature: number;
    top_k: number;
    top_p: number;
    frepetition_penalty: number;
    max_tokens: string;
    prompt: string;
    prefix?: string;
    suffix?: string;
    defaultDialogueList?: any[];
}
@Component({
    components: {
        ModelDetail,
        DynamicForm,
    },
})
export default class MultiRoundDialogue extends Vue {
    @Prop() model!: IModel;
    @Prop() index!: number;
    @Prop() firstQuestion!: string;
    @Prop() dialogueData!: Record<number | string, any>;
    @Prop() isDoubleBlind!: number;
    @Prop() isEditModelContent!: number;
    @Prop() formConfig!: any;

    dialogueList = [];
    message = '';
    // isShowParams = true;
    configKeyList = ['prompt', 'max_tokens', 'temperature', 'top_p', 'top_k', 'frepetition_penalty'];

    workerId = null;
    // prefix = ''; // 'USER';
    // suffix = ''; //'ASSISTANT';

    $refs: {
        scrollMark: HTMLDivElement;
    };

    beBeingSent = false; // 是否发送中
    selectDialogueIndex = null;
    formData = {};
    showCancelBtn = false;

    async handleSendMessage() {
        if (!this.message.trim()) {
            this.$message.warning('请输入内容！');
            return false;
        }

        if (!this.workerId || this.beBeingSent) {
            this.$message.warning('请稍后');
            return false;
        }

        if (this.selectDialogueIndex !== null && this.formConfig) {
            this.$message.warning('请先确认模型对话内容的标注！');
            return false;
        }

        try {
            this.beBeingSent = true;
            this.dialogueList.push({
                content: this.message,
                role: 'USER',
            });
            this.scrollBottom();
            const params = {
                workerId: this.workerId,
                parameter: {
                    topP: this.model.top_p,
                    topK: this.model.top_k,
                    temperature: this.model.temperature,
                    maxOutputLength: this.model.max_tokens,
                    repetitionPenalty: this.model.frepetition_penalty,
                },
                prompts: this.dialogueList
                    .map((d, i) => {
                        const prefix = this.model.prefix.replace('{round}', `${Math.floor(i / 2)}`);
                        const suffix = this.model.suffix.replace('{round}', `${Math.floor(i / 2)}`);
                        return d.role === 'USER'
                            ? {
                                  role: d.role,
                                  content: `${prefix}${d.content}${suffix}`,
                              }
                            : {
                                  role: d.role,
                                  content: d.content,
                              };
                    })
                    .filter((d) => d.role !== 'ERROR'),
                promptPrefix: this.model.prefix,
                promptSuffix: this.model.suffix,
            };
            this.message = '';
            await getChatGenerate(params, (progressEvent) => {
                const responseMessageList = progressEvent.currentTarget.responseText.split('data:');
                let data = null;
                try {
                    data = JSON.parse(responseMessageList.at(-1).replace(/\n\n$/, ''));
                } catch (e1) {
                    try {
                        data = JSON.parse(responseMessageList.at(-2).replace(/\n\n$/, ''));
                    } catch (e2) {
                        console.log('JSON解析失败', e1, e2, responseMessageList);
                    }
                }
                if (data) {
                    if (data.finishReason === 'worker_error') {
                        this.dialogueList.push({
                            content: data.result,
                            role: 'ERROR',
                        });
                    } else {
                        const formData = {};
                        this.formConfig?.formItemList?.forEach((item) => {
                            formData[item.vModel] = item.defaultValue;
                        });
                        if (this.dialogueList[this.dialogueList.length - 1].role === 'USER') {
                            this.dialogueList.push({
                                content: data.result,
                                modelContent: data.result,
                                role: 'BOT',
                                formData,
                            });
                        } else {
                            this.dialogueList[this.dialogueList.length - 1] = {
                                content: data.result,
                                modelContent: data.result,
                                role: 'BOT',
                                formData,
                            };
                        }
                    }
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.scrollBottom();
            this.beBeingSent = false;
            this.selectDialogueIndex = this.formConfig ? this.dialogueList.length - 1 : null;
        }
    }

    scrollBottom() {
        setTimeout(() => {
            this.$refs.scrollMark?.scrollIntoView({ block: 'end', behavior: 'smooth' });
        });
    }

    handleEnter(e) {
        if ([8, 13].includes(e.keyCode)) {
            if (!this.message) {
                return false;
            }
            e.preventDefault();
            this.handleSendMessage();
        }
    }

    async getWorkerId() {
        try {
            const { data } = await getListWorker(this.model.model_id);
            const runningWorkerList = data.filter((r) => r.status === 'RUNNING');
            if (runningWorkerList.length === 0) {
                this.$message.warning(`模型Id为${this.model.model_id}的模型未启动成功。`);
                return false;
            }
            this.workerId = runningWorkerList.sort((a, b) => b.expireTime - a.expireTime)[0]?.workerId || null;
        } catch (error) {
            console.log(error, '获取失败');
        }
    }

    transSaveData() {
        return {
            [this.index]: {
                dialogueList: this.dialogueList,
                model: this.model,
            },
        };
    }

    async handleGetWorkerId() {
        await this.getWorkerId();

        if (this.$route.name !== 'annotation-work') {
            return false;
        }
        if (this.firstQuestion && this.workerId && !this.dialogueData) {
            this.message = this.firstQuestion;
            this.handleSendMessage();
        }
    }

    handleEditContent(editContentIndex, message) {
        editContentDialog(message)
            .then((editMsg) => {
                this.dialogueList[editContentIndex].content = editMsg;
                this.$forceUpdate();
            })
            .catch(() => {
                //
            });
    }

    handleCarryOut(carryOutIndex, msg) {
        this.$mtd
            .confirm({
                title: '确认信息',
                message: '此操作将清空该条对话之后的对话内容，是否继续编辑。',
                width: '430px',
                showCancelButton: true,
            })
            .then(() => {
                this.dialogueList = this.dialogueList.slice(0, carryOutIndex);
                this.message = msg;
                this.handleSendMessage();
            })
            .catch(() => {
                //
            });
    }

    handleUnfold(i) {
        this.showCancelBtn = true;
        this.selectDialogueIndex = i;
        if (!this.dialogueList[i].formData) {
            this.dialogueList[i].formData = {};
            this.formConfig?.formItemList?.forEach((item) => {
                this.$set(this.dialogueList[i].formData, item.vModel, item.defaultValue);
            });
        }
        Object.keys(this.formData).forEach((key) => {
            this.formData[key] = this.dialogueList[i].formData[key];
        });
    }

    handleConfirmSelectModelContent(dialogue) {
        Object.keys(this.formData).forEach((key) => {
            dialogue.formData[key] = this.formData[key];
        });
        this.formConfig?.formItemList.forEach((item) => {
            this.formData[item.vModel] = item.defaultValue;
        });
        this.selectDialogueIndex = null;
        this.scrollBottom();
    }

    handleCancelSelectModelContent() {
        this.formConfig?.formItemList.forEach((item) => {
            this.formData[item.vModel] = item.defaultValue;
        });
        this.selectDialogueIndex = null;
        this.showCancelBtn = false;
    }

    relaunch() {
        this.handleCancelSelectModelContent();
        this.dialogueList = [];
        if (this.model.defaultDialogueList) {
            this.dialogueList = cloneDeep(this.model.defaultDialogueList);
        }
        if (this.model.prompt) {
            this.dialogueList.unshift({
                content: this.model.prompt,
                role: 'BOT',
            });
        }
        if (this.firstQuestion && this.workerId) {
            this.message = this.firstQuestion;
            this.handleSendMessage();
        }
    }

    async init() {
        if (this.dialogueData) {
            this.dialogueList = cloneDeep(this.dialogueData.dialogueList);
        }
        if (this.model.defaultDialogueList && !this.dialogueData) {
            this.dialogueList = cloneDeep(this.model.defaultDialogueList);
        }
        if (this.model.prompt && !this.dialogueData) {
            this.dialogueList.unshift({
                content: this.model.prompt,
                role: 'BOT',
            });
        }

        this.formConfig?.formItemList?.forEach((item) => {
            this.$set(this.formData, item.vModel, item.defaultValue);
        });
        this.handleGetWorkerId();
    }

    mounted() {
        this.init();
    }
}

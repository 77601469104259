let env = process.env.CONFIG_ENV || 'local';
export interface EnvironmentOption {
    name: string;
    url: string;
    env: string[];
}

const EnvironmentList: EnvironmentOption[] = [
    {
        name: '线上',
        // TODO:
        url: 'http://cafe.web.sankuai.com',
        env: ['prod', 'production', 'staging', 'beta'],
    },
    {
        name: '测试',
        url: 'http://cafe.web.test.sankuai.com',
        env: ['test'],
    },
    {
        name: '本地开发',
        url: 'http://localhost:8080',
        env: ['development', 'dev'],
    },
];

const BaseurlList = {
    local: '//cmark.pub.adp.test.sankuai.com/',
    test: '',
    staging: '',
    production: '//17gotest.com/',
};

const EnvMap = {
    dev: ['development', 'dev'],
    prod: ['prod', 'production', 'staging', 'beta'],
    test: ['test'],
};

function isDev(env: string) {
    return EnvMap.dev.includes(env);
}

export const isProd = EnvMap.prod.includes(process.env.CONFIG_ENV);

export const VisibleEnvironmentList = EnvironmentList.filter((v) => isDev(process.env.CONFIG_ENV) || !v.env.some(isDev));

export const Baseurl = BaseurlList[env];

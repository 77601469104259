import { defineEnum } from '@ai/flow-utils/lib';

export interface IUser {
    name: string;
    misId: string;
    avatarUrl: string;
}

export const Gender = defineEnum({
    Male: {
        label: '男',
        value: 0,
    },
    Female: {
        label: '女',
        value: 1,
    },
} as const);

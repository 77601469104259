const formItemSchema = {
    formItemType: 'el-input',
    label: '文本框',
    vModel: 'input',
    required: false,
    defaultValue: '',
    attr: {
        type: 'text',
        rows: 2,
    },
};
const formItemConfig = {
    formStyle: {
        labelWidth: '100',
        size: 'mini',
        span: 20,
    },
    formItemList: [
        {
            formItemType: 'el-input',
            label: '名称',
            vModel: 'label',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '绑定值',
            tips: '区块内唯一Key值',
            vModel: 'vModel',
            required: true,
        },
        {
            formItemType: 'el-input',
            label: '未标注值',
            tips: '未标注时的默认结果',
            vModel: 'noAnnotationDefaultValue',
        },
        {
            formItemType: 'el-radio-group',
            label: '类型',
            vModel: 'attr.type',
            required: true,
            options: [
                { label: 'text', name: '输入框' },
                { label: 'textarea', name: '文本域' },
                { label: 'texteditor', name: '富文本' },
            ],
        },
        {
            formItemType: 'el-radio-group',
            label: '是否必填',
            vModel: 'required',
            required: true,
            options: [
                { label: true, name: '是' },
                { label: false, name: '否' },
            ],
        },
        {
            formItemType: 'el-input-number',
            label: '宽度',
            vModel: 'span',
            attr: { type: 'number', min: 1, max: 24 },
        },
        {
            formItemType: 'el-input-number',
            label: '行数',
            vModel: 'attr.rows',
            tips: '类型选择文本域时生效',
            attr: { type: 'number', min: 1 },
        },
    ],
};
const formItemInfo = {
    name: '输入框',
};
export default { formItemSchema, formItemConfig, formItemInfo };

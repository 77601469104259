import qs from 'qs';

export default {
    install(Vue: any, options: any) {
        let hashQuery = location.hash.replace('#', '').replace('/', '').replace('?', '');
        let hashQsObj = qs.parse(hashQuery);
        let historyQuery = location.search.replace('?', '');
        let historyQsObj = qs.parse(historyQuery);
        let qsObj = historyQsObj ? historyQsObj : hashQsObj;
        window['$qsObj'] = qsObj;
        // console.log('$qsObj:', $qsObj);
    },
};

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import isEmpty from 'lodash/isEmpty';
import formItemWrapper from '@/components/dynamic-components/form-item-wrapper';
import { DynamicFormItems } from '@/components/dynamic-components';
import cloneDeep from 'lodash/cloneDeep';

@Component({ components: { ...formItemWrapper(['ElRadioGroup']), ...DynamicFormItems } })
export default class DynamicForm extends Vue {
    @Prop({ default: () => ({}) })
    formConfig!: any;
    @Prop({ default: () => ({}) })
    formData!: any;
    @Prop({ default: false })
    onlyDisplay: boolean;
    @Prop({ default: '' })
    formDataId: string;

    $refs: { dynamicForm: any };
    defaultLabelWidth = 0;
    // defaultLabelWidth = 100;
    defaultRowGutter = 0;
    defaultItemWidth = '100%';
    defaultSize = 'medium';
    formItemProxyList = [];
    richTextComponentLoaded = false;
    richTextComponentLoadPromise = null;

    @Watch('formData', { immediate: true })
    handleFormDataChange(value) {
        if (this.formItemProxyList.length) {
            this.formItemProxyList.forEach((item) => {
                if (Object.getOwnPropertyDescriptor(item, 'vModelProxy')) {
                    item.vModelProxy = this.getFormDefaultValue(item.originalFormItem);
                }
            });
        }
        !this.onlyDisplay && this.$emit('update:formData', value);
    }

    @Watch('formItemList', { immediate: true })
    formItemListChange() {
        const self = this;
        this.formItemProxyList = this.formItemList.map((item, formItemIdx) => {
            !isEmpty(item.on) &&
                Object.keys(item.on).forEach((key) => {
                    let originCb = item.on[key];
                    let _this = this;
                    item.on[key] = function (params) {
                        if (originCb) {
                            let args = [].slice.call(arguments);
                            let finalArgs = [formItemIdx, ...args];
                            originCb.apply(_this, finalArgs);
                        }
                        _this.$emit(key, params);
                    };
                });

            const obj: any = { originalFormItem: item };
            if (!isEmpty(item.vModel)) {
                Object.defineProperty(obj, 'vModelProxy', {
                    get() {
                        return this.originalFormItem.vModel.split('.').reduce((target, seg) => {
                            if (target) {
                                target = target[seg];
                            }
                            return target;
                        }, self.formData);
                    },
                    set(value) {
                        const segments = this.originalFormItem.vModel.split('.');
                        segments.reduce((target, seg, length) => {
                            if (length === segments.length - 1) {
                                self.$set(target, seg, value);
                            } else {
                                !target[seg] && self.$set(target, seg, {});
                            }
                            target = target[seg];
                            return target;
                        }, self.formData);
                    },
                });
                obj.vModelProxy = this.getFormDefaultValue(item);
            }
            return obj;
        });
    }

    get formStyle() {
        return Object.assign(
            {
                labelWidth: this.defaultLabelWidth,
                size: this.defaultSize,
                rowGutter: this.defaultRowGutter,
                itemWidth: this.defaultItemWidth,
            },
            this.formConfig.formStyle
        );
    }
    get formItemList() {
        this.formConfig.formItemList.forEach((item) => {
            // 级联选择器，支持限制是否必须选择叶子结点

            // 这里直接修改props不太好，但暂时没想到其他好的方法，而且改的是对象类型的属性，所以影响还在把控范围内
            // 之前尝试直接深拷贝一份this.formConfig但是出现其他问题失败了
            if (item.formItemType === 'el-cascader') {
                item.on = { change: this.onCascaderChange };
            }
        });
        return this.formConfig.formItemList || [];
    }
    onCascaderChange(formItemIdx, value, selectedOptions, nodes, lastNode) {
        console.log('cascader change', formItemIdx, value, selectedOptions, nodes, lastNode);
        console.log('this.formItemProxyList[formItemIdx]', this.formItemProxyList[formItemIdx]);
        let cascaderChangeParams = {
            formDataId: this.formDataId,
            selectedOptions,
        };
        console.log('this.formConfig', this.formConfig, this.formData);
        this.$emit('onCascaderChange', cascaderChangeParams);
        if (!Array.isArray(selectedOptions[0])) return;
        let targetFormItemIsMustSelectLeafNode = this.formItemProxyList[formItemIdx]?.originalFormItem?.isMustSelectLeafNode;
        if (targetFormItemIsMustSelectLeafNode) {
            // 如果叶子节点只有1个，selectedOptions不会返回叶子结点，原因未知
            let legalSelectedOptions = selectedOptions.filter(
                (selOpt) => !selOpt?.[selOpt.length - 1]?.children || selOpt?.[selOpt.length - 1]?.children.length === 1
            );
            this.formItemProxyList[formItemIdx].vModelProxy = legalSelectedOptions.map((lso) => {
                let lastItem = lso[lso.length - 1];
                let result = lso.map((lsoItem) => lsoItem.value);
                return lastItem.children ? [...result, lastItem.children[0]?.value] : result;
            });
        }
    }
    get rules() {
        return this.formItemList.reduce((rules, item) => {
            if (item.rules && item.rules.length > 0) {
                rules[item.vModel] = item.rules;
            }
            return rules;
        }, {});
    }

    get formItemVisibility() {
        return this.formItemList.reduce((visibility, item) => {
            if (item.visible) {
                // visibility[item.vModel] = isEqualWith(Object.assign({}, this.formData, item.visible), this.formData, this.customizer);
                visibility[item.vModel] = this.compareObj(item.visible, this.formData);
            } else {
                visibility[item.vModel] = true;
            }
            return visibility;
        }, {});
    }

    compareObj(visible, formData) {
        if (Array.isArray(visible) && Array.isArray(formData)) {
            //todo 完善当item是对象时的逻辑
            return !visible.find((item) => !formData.includes(item));
        } else if (typeof visible === 'object' && typeof formData === 'object') {
            return !Object.keys(visible).find((key) => !this.compareObj(visible[key], formData[key]));
        } else {
            return formData === visible;
        }
    }
    getFormDefaultValue(item) {
        //循环标注区会循环引用配置
        const formDataValue = item.vModel.split('.').reduce((target, seg) => {
            if (target) {
                target = target[seg];
            }
            return target;
        }, this.formData);
        if (formDataValue !== undefined && formDataValue !== null && formDataValue !== '') {
            return formDataValue;
        } else if (item.defaultValue) {
            return cloneDeep(item.defaultValue);
        } else if (item.formItemType === 'daterangeIncrease') {
            return [['', '']];
        } else if (
            (formDataValue == '' && item.formItemType === 'el-checkbox-group') ||
            item.type === 'dates' ||
            item.formItemType === 'el-checkbox-group' ||
            item.formItemType === 'el-cascader' ||
            item.formItemType === 'input-plus' ||
            (item.type && item.type.indexOf('range') !== -1)
        ) {
            return [];
        } else {
            return '';
        }
    }

    getComponentsName(name, item?) {
        switch (name) {
            case 'el-checkbox-group':
                return 'mtd-checkbox-group';
            case 'el-input':
                if (item?.attr?.type === 'textarea') {
                    return 'mtd-textarea';
                } else if (item?.attr?.type === 'texteditor') {
                    // 如果有富文本的formItem，再动态加载
                    this.loadTextEditorAsync();
                    return 'richText';
                }
                return 'mtd-input';
            case 'el-select':
                return 'mtd-select';
            case 'el-radio-group':
                return 'mtd-radio-group';
            case 'el-input-number':
                return 'mtd-input-number';
            case 'el-color-picker':
                return 'mtd-color-picker';
            case 'el-cascader':
                return 'mtd-cascader';
            default:
                return name;
        }
    }

    loadTextEditorAsync() {
        if (!this.richTextComponentLoaded && !this.richTextComponentLoadPromise) {
            this.richTextComponentLoadPromise = import('./richText.vue')
                .then((res) => {
                    if (res.default) {
                        Vue.component('RichText', res.default);
                        this.richTextComponentLoaded = true;
                        this.richTextComponentLoadPromise = null;
                    } else {
                        return Promise.reject();
                    }
                })
                .catch((e) => {
                    this.richTextComponentLoaded = false;
                    this.richTextComponentLoadPromise = null;
                });
        }
    }

    getComponentAttribute(attr, formItemType, item) {
        if (formItemType === 'el-cascader') {
            attr.filterable = item.filterable;
            attr.multiple = item.multiple;
        }
        return attr;
    }

    validate() {
        return new Promise((resolve, reject) => {
            if (this.$refs.dynamicForm) {
                (this.$refs.dynamicForm as any)
                    .validate((v, message) => {
                        if (v) {
                            resolve(v);
                        } else {
                            this.$message.error('表单校验失败!');
                            reject(message);
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            } else {
                reject('表单不存在');
            }
        });
    }
}

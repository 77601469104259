let TaskStatus = {
    0: '未创建(0)',
    1: '标注中(1)',
    2: '外部质检中(2)',
    3: '美团质检中(3)',
    4: '已完成(4)',
    5: '任务待下发(5)',
    99: '冻结状态(99)',
};
export { TaskStatus };

// 整个的check
let CheckStatus = {
    0: '未创建(0)',
    1: '质检中(1)',
    2: '质检完成(2)',
    3: '等待复检(3)',
    4: '复检中(4)',
};
export { CheckStatus };

// 任务结算状态
let PaymentStatus = {
    ALL: '任务完全结算',
    PARTIAL: '任务部分结算',
    NONE: '任务未结算',
};
export { PaymentStatus };

// 单独的一个case的check状态
let CheckResult = {
    0: '未质检(0)',
    1: '质检通过(1)',
    2: '质检不通过(2)',
    3: '质检不通过，修改后可提交(3)',
    4: '质检不通过，直接可提交(4)',
};
export { CheckResult };

// 单独的一个case的check状态
let RecheckResult = {
    0: '未质检(0)',
    1: '复检通过(1)',
    2: '复检不通过(2)',
};
export { RecheckResult };

// 平台角色信息
const RoleMap = {
    1: '标注人员',
    2: '外部质检',
    3: '美团质检',
    4: '管理员',
    5: '复审',
    6: '复检人员',
};
export { RoleMap };

// case状态信息
const statusCodeMap = {
    0: '未分配数量',
    1: '标注中未暂存',
    2: '已暂存未提交',
    3: '已提交未质检',
    4: '重新标注',
    6: '外包质检中',
    7: '美团质检中',
    11: '有问题待处理(删除case)',
    99: '待美团质检',
    100: '美团质检完成',
    80: '待复检',
    81: '复检中',
};

// 多标状态映射
const multiTagStatusCodeMap = {
    0: '未分配数量',
    1: '标注中未暂存',
    2: '已暂存未提交',
    3: '已提交未质检',
    4: '重新标注',
    6: '一轮质检中',
    7: '二轮质检中',
    333: '三轮质检中',
    11: '有问题待处理(删除case)',
    99: '待美团质检',
    100: '美团质检完成',
    80: '待复检',
    81: '复检中',
    5: '标注完成，待盲检',
    51: '盲检中',
    // 52: '盲检标注暂存',
    53: '盲检完成已提交',
};

// 拟合结果状态映射
const fitResultStatusMap = {
    1: '单人标注结果',
    10: '一人拟合一致',
    20: '两人拟合一致',
    21: '两人拟合不一致',
    30: '三人拟合一致',
    31: '三人拟合不一致',
    40: '四人拟合一致',
    41: '四人拟合不一致',
    99: '拟合不一致',
};

export { statusCodeMap, multiTagStatusCodeMap, fitResultStatusMap };

// SBS标注选项Map
let SBSOptionMap = {
    all: '两侧都还可以',
    uncertain: '两侧都非常不好',
    mt: '美团明显好',
    dp: '点评明显好',
};
export { SBSOptionMap };

let DPComparedOptionMap = {
    left: '左侧好',
    right: '右侧好',
    all: '都好',
    uncertain: '都不好',
};
let MTComparedOptionMap = {
    left: '左侧好',
    right: '右侧好',
    all: '都好',
    uncertain: '都不好',
};
export { DPComparedOptionMap, MTComparedOptionMap };

const PoiItemMap = {
    1: '喜欢',
    2: '一般',
    3: '不相关',
};
export { PoiItemMap };

const SugPoiItemMap = {
    1: '相关',
    2: '重复',
    3: '不相关',
};
export { SugPoiItemMap };

const MtVideoPoiItemMap = {
    0: '差',
    1: '一般',
    2: '较好',
    3: '好',
};
export { MtVideoPoiItemMap };

const MTMCSinglePoiItemMap = {
    1: '强相关',
    2: '次强相关',
    3: '弱相关',
    4: '不相关',
};
export { MTMCSinglePoiItemMap };

// TODO 默认优选标注内容，需确认
const MTYXSinglePoiItemMap = {
    1: '强相关',
    2: '次强相关',
    3: '弱相关',
    4: '不相关',
};
export { MTYXSinglePoiItemMap };

const MTYXSinglePoiItemArray = [
    { name: '强相关', id: 1 },
    { name: '次强相关', id: 2 },
    { name: '弱相关', id: 3 },
    { name: '不相关', id: 4 },
];
export { MTYXSinglePoiItemArray };

const MTRECOMMENDSinglePoiItemMap = {
    1: '通过',
    2: '不通过',
    3: '--',
};
export { MTRECOMMENDSinglePoiItemMap };

// SBS回归
let SBSFlyBackOptionMap = {
    all: '两侧均还可以',
    uncertain: '两侧都非常不好',
    old: '左侧明显好',
    new: '右侧明显好',
};
export { SBSFlyBackOptionMap };

//点评上报case
let dpReportMap = {
    0: '非badcase',
    1: '是badcase',
};
export { dpReportMap };

// 质检归因
let ReasonType = {
    0: '距离',
    1: '露出',
    2: '图片',
    3: '星级',
    4: '品类',
    5: '唯一命中',
    6: '商圈',
    7: '地标',
    8: '常识',
    9: '聚块',
    10: '异地',
    11: '双标',
    12: '可解释性',
    13: '其他',
};
export { ReasonType };

// 质检状态
let CaseStatus = {
    0: '未分配',
    1: '已分配，标注中',
    2: '标注暂存',
    3: '标注完成，待外包质检',
    4: '不合格，返工状态',
    5: '质检中',
    11: '有问题需要待处理',
    99: '待美团质检（由外包质检人员发起提交）',
    100: '美团质检合格，验收完成',
};
export { CaseStatus };

// 满意度细化
let PoiSatisfacMap = {
    0: '好',
    1: '中',
    2: '差',
    3: '图好',
    4: '图中',
    5: '图差',
    6: '文好',
    7: '文中',
    8: '文差',
};
export { PoiSatisfacMap };

// 商户搜索满意度细化
let PoiShopSearchMap = [
    {
        type: 'radio',
        list: [
            { id: 1, name: '图好' },
            { id: 2, name: '图中' },
            { id: 3, name: '图差' },
        ],
    },
    {
        type: 'radio',
        list: [
            { id: 4, name: '标签好' },
            { id: 5, name: '标签中' },
            { id: 6, name: '标签差' },
        ],
    },
    {
        type: 'radio',
        list: [
            { id: 7, name: '推荐理由好' },
            { id: 8, name: '推荐理由中' },
            { id: 9, name: '推荐理由差' },
        ],
    },
    {
        type: 'radio',
        list: [
            { id: 10, name: '下挂好' },
            { id: 11, name: '下挂中' },
            { id: 12, name: '下挂差' },
        ],
    },
    {
        type: 'radio',
        list: [
            { id: 13, name: '整体协调' },
            { id: 14, name: '轻度冗余' },
            { id: 15, name: '冗余矛盾' },
        ],
    },
];
export { PoiShopSearchMap };

//默认备注下拉框
let caseRemarkList = [
    {
        text: '疑难case',
        value: 'hard',
    },
    {
        text: '丢弃',
        value: 'abandon',
    },
    {
        text: '未还原case',
        value: 'notemplate',
    },
    {
        text: '规则外case',
        value: 'outrule',
    },
    {
        text: '其他',
        value: 'other',
    },
];
export { caseRemarkList };

// 质检状态list
let CheckResultList = [
    {
        text: '未质检(0)',
        value: 0,
    },
    {
        text: '质检通过(1)',
        value: 1,
    },
    {
        text: '质检不通过(2)',
        value: 2,
    },
];
export { CheckResultList };

// 大盘归类细则 https://km.sankuai.com/page/1228019499
let classifySelectConfig = {
    segmentList: {
        0: '去店里',
        1: '点外卖',
        2: '超市到家',
        3: '美团买药',
        4: '鲜花外卖',
        5: '生鲜到家',
        6: '美团优选',
        7: '包邮到家',
        8: '推荐',
        9: '上门服务',
        10: '精选菜谱',
        11: '排行榜',
        12: '优惠精选',
        13: '热门点评',
        14: '商品新框架',
        15: '非百花园',
        16: '非Segment',
    },
    queryList: [
        {
            value: '误召回',
            requied: true,
        },
        {
            value: '列表排序',
            requied: true,
        },
        {
            value: '解释性',
            requied: true,
        },
        {
            value: '漏召回',
            requied: false,
        },
        {
            value: '搜索词未被正确改写',
            requied: false,
        },
        {
            value: '用户强需求履约形式缺失',
            requied: false,
        },
        {
            value: '地标问题',
            requied: false,
        },
        {
            value: '本异地识别问题',
            requied: false,
        },
        {
            value: '聚块排序',
            requied: true,
        },
        {
            value: '广告问题',
            requied: false,
        },
        {
            value: '图片问题',
            requied: true,
        },
        {
            value: '天花板',
            requied: false,
        },
        {
            value: '黑名单',
            requied: false,
        },
        {
            value: '产品策略',
            requied: false,
        },
        {
            value: 'Query无意义',
            requied: false,
        },
        {
            value: '标注系统问题',
            requied: false,
        },
        {
            value: '标注问题',
            requied: false,
        },
    ],
};
export { classifySelectConfig };

let goTestConfig = {
    mt: '美团',
    dp: '点评',
    pd: '频道内',
    xy: '新氧',
    xhs: '小红书',
    ele: '饿了么',
    bbs: '宝宝树',
    fb: '58',
    tb: '淘宝',
    jd: '京东',
    fz: '飞猪',
    xc: '携程',
    gd: '高德',
};
export { goTestConfig };

<script>
const REG_LINK = /^\w+:\/\//;

export default {
    name: 'JsonString',
    functional: true,
    props: {
        jsonValue: {
            type: String,
            required: true,
        },
    },
    render(h, { props }) {
        let value = props.jsonValue.toString();
        const islink = REG_LINK.test(value);
        let domProps;
        if (value.length > 10000) {
            //防止字符串超长打崩浏览器
            value = value.substring(0, 10000) + `......(省略${value.length - 10000}个字符)`;
        }
        if (islink) {
            value = `<a href="${value}" target="_blank" style="color: #0366d6;">${value}</a>`;
            domProps = {
                innerHTML: `"${value}"`,
            };
        } else {
            domProps = {
                innerText: `"${value}"`,
            };
        }

        return h('span', {
            class: {
                'jv-item': true,
                'jv-string': true,
            },
            domProps: {
                ...domProps,
            },
        });
    },
};
</script>

let keepAlive = false;

export default {
    path: '/',
    redirect: '/login',
    component: () => import('@/views/Layout.vue'),
    meta: {
        title: '首页',
    },
    children: [
        {
            path: '/task-list',
            name: 'taskList',
            component: () => import('@/views/application/task-list.vue'),
            meta: {
                icon: 'mtdicon mtdicon-align-justify',
                title: '任务列表',
                keepAlive: keepAlive,
            },
        },
        {
            path: '/task-work',
            name: 'taskWork',
            component: () => import('@/views/application/task-work.vue'),
            meta: {
                icon: 'mtdicon mtdicon-checkbox-unchecked',
                title: '标注工作区',
                keepAlive: keepAlive,
            },
        },
        {
            path: '/task-temp',
            name: 'taskTemp',
            component: () => import('@/views/application/task-temp.vue'),
            meta: {
                icon: 'mtdicon mtdicon-lookup',
                title: '标注暂存区',
                keepAlive: keepAlive,
            },
        },
        {
            path: '/chat',
            name: 'chat',
            hid: true,
            component: () => import('@/views/application/chat.vue'),
            meta: {
                icon: 'mtdicon mtdicon-comment',
                title: 'chat',
                keepAlive: keepAlive,
            },
        },
    ],
};

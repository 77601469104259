import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import MTD from '@ss/mtd-vue';
import '@ss/mtd-vue/lib/theme2/index.css';
Vue.use(MTD);

import BackendUI from '@ai/backend-ui';
Vue.use(BackendUI);
import '@ai/backend-ui/lib/index.css';

import api from '@/api';
Vue.prototype.$api = api;

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import JsonViewer from '@/components/nlp-json-viewer';
Vue.use(JsonViewer);

import '@ss/mtd-vue-mobile/lib/styles/index.css';
import MTDM from '@ss/mtd-vue-mobile';
Vue.use(MTDM);

import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(VueViewer);

// Element组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 按需引入
import { Loading, MessageBox, Notification } from 'element-ui';
Vue.use(Loading.directive);
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$confirm = MessageBox.confirm;

import '@/plugins';

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

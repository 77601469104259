export default {
    install(Vue: any, options: any) {
        // log
        let $pure = (val: any) => {
            // undefined 不能被stringify
            if (val === undefined) {
                return val;
            }
            return JSON.parse(JSON.stringify(val));
        };
        let $log: any = (...arg: any[]) => {
            let _arg = arg.map((_) => $pure(_));
            console.log(..._arg);
        };
        Vue.prototype.console = window.console;
        Vue.prototype.$log = $log;
        Vue.prototype.$pure = $pure;
        window['$log'] = $log;

        let $bus = new Vue();

        // bus
        Vue.prototype.$bus = $bus;
        Vue.prototype.$promiseTry = $bus;

        // promise反转
        let $promiseTry = (p) => {
            return new Promise((resolve, reject) => {
                p.then((val) => {
                    resolve(val);
                }).catch((e) => {
                    console.log('$promiseTry:', e);
                    resolve(false);
                });
            });
        };
        window.$promiseTry = $promiseTry;
        window.$bus = $bus;
    },
};
























































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { caseRemarkList } from '@/utils/constants';
@Component
export default class AnnotationRemarkInfo extends Vue {
    @Prop()
    caseData?: any;

    @Prop()
    roleId: any;

    @PropSync('remarkStr') remarkStrInner: string;
    @PropSync('remarkStrNew') remarkStrNewInner: any;
    @PropSync('remarkStrCheck') remarkStrCheckInner: any;
    @PropSync('remarkStrWbCheck') remarkStrWbCheckInner: any;
    @PropSync('remarkStrReCheck') remarkStrReCheckInner: any;
    @Prop() multiExtraInfo: any;
    @Prop({ type: Array })
    caseRemarkArr: Array<any>;

    caseRemarkList = caseRemarkList;

    needToEmpty = false;
    handleChangeRemarkStrNewInner(val: string[]) {
        this.$emit('handleChangeRemarkStrNewInner', this.needToEmpty && val.length);
    }

    mounted() {
        const { cleanUp } = this.$route.query;
        this.needToEmpty = (cleanUp && Number(cleanUp) === 1) || this.$store.state.common?.currentTask?.cleanUp === 1;
        if (this.caseRemarkArr && typeof this.caseRemarkArr === 'object' && this.caseRemarkArr.length)
            this.caseRemarkList = this.caseRemarkArr;
    }
}




















































import { Vue, Component } from '@/decorators';
import api from '@/api';

@Component
export default class ChatWindow extends Vue {
    messageArr: any = [];
    curMessage: any = '';
    loading: any = false;
    loadingHis: any = false;
    userInfo = localStorage.getItem('User_Info');

    async mounted() {
        await this.listHistoryRound();
        this.scrollBottom();
    }

    send(e) {
        if (e?.shiftKey) {
            return;
        }
        this.predict(this.curMessage);
    }

    async updateHistoryMessage() {
        const id = this.messageArr[0]?.id;
        id && (await this.listHistoryRound(id));
    }

    updateLastMessage() {
        this.listHistoryRound(0, 1, (lastMessage) => {
            if (lastMessage && !this.messageArr.at(-1).id && this.messageArr.at(-2).id !== lastMessage.id) {
                this.messageArr.splice(-1, 1, lastMessage);
            }
        });
    }

    scrollBottom(scrollTop?) {
        this.$nextTick(() => {
            const messageContainer = this.$refs.messageContainer as Element;
            messageContainer.scrollTop = scrollTop || messageContainer.scrollHeight;
        });
    }

    async handleScroll(e) {
        const container = e.target;
        const oldScrollHeight = container.scrollHeight;
        if (container.scrollTop === 0) {
            await this.updateHistoryMessage();
            if (container.scrollHeight === oldScrollHeight) {
                this.$mtd.message.warning('无历史记录');
            }
            container.scrollTop = container.scrollHeight - oldScrollHeight;
        }
    }

    async listHistoryRound(minRoundId = 0, roundCount = 10, callBack?) {
        if (this.loadingHis) {
            return;
        }
        try {
            this.loadingHis = true;
            const data = {
                minRoundId,
                roundCount,
            };
            const res = await api.common.listHistoryRound(data);
            if (res.rescode === 0) {
                const list = res.data?.list || [];
                list.reverse();
                callBack ? callBack(list[0]) : this.messageArr.unshift(...list);
            }
        } catch (e) {
            this.$mtd.message.error(e || '获取历史对话记录失败，请检查或刷新重试');
        } finally {
            this.loadingHis = false;
        }
    }

    async predict(query: string) {
        if (!query.trim()) {
            return;
        }
        try {
            this.loading = true;
            const newMessage = {
                userQuery: query,
                assistantAnswer: undefined,
            };
            this.messageArr.push(newMessage);
            this.scrollBottom();
            this.curMessage = '';
            const res = await api.common.predict({ query });
            if (res.rescode === 0) {
                newMessage.assistantAnswer = res.data?.assistantAnswer || '';
                // this.updateLastMessage();
            }
        } catch (e) {
            this.$mtd.message.error(e || '获取预测信息失败，请检查或刷新重试');
        } finally {
            this.scrollBottom();
            this.loading = false;
        }
    }
}

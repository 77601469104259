























import { Component, Vue, Model, Watch, Prop } from 'vue-property-decorator';

@Component
export default class InputPlus extends Vue {
    name = 'input-plus';
    @Model('input') readonly value;
    @Prop({
        default: () => [{ name: 'inputPlus', vModel: 'inputPlus' }],
    })
    inputConfig;

    @Prop({ default: true })
    showTitle: boolean;

    @Watch('value', { immediate: true })
    handleValueChange() {
        if (!this.value) {
            this.$emit('input', []);
        }
    }

    handleAddInput() {
        const obj = this.inputConfig.reduce((obj, item) => {
            obj[item.vModel] = item.defaultValue || '';
            return obj;
        }, {});
        this.value.push(obj);
    }
    handleRemoveInput(index) {
        this.value.splice(index, 1);
    }
}

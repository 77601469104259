<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'App',
    created() {
        //在页面加载时读取localStorage里的状态信息
        if (localStorage.getItem('User_Info')) {
            let common = {
                common: {
                    loginName: localStorage.getItem('User_Info'),
                },
            };
            this.$store.replaceState(Object.assign({}, common));
        }
        // console.log('be-refresh', this.$store.state);
        //在页面刷新时将vuex里的信息保存到localStorage里
        // window.addEventListener('beforeunload', () => {
        //     localStorage.setItem('User_Info', this.$store.state.loginName);
        // });
    },
};
</script>
<style lang="scss">
html,
body,
#app {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
</style>

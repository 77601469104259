















































































import cloneDeep from 'lodash/cloneDeep';
import { Vue, Component, Prop } from 'vue-property-decorator';
import editContentDialog from './edit-content-dialog';
import addDialog from './add-dialog';
import { getWorkerId } from '@/utils/multiRound';

const roleMap = {
    USER: 'Q',
    AGENT: 'A',
    TOOL: 'T',
};

@Component
export default class MultiRoleWindow extends Vue {
    @Prop() firstQuestion: string;
    @Prop() defaultDialogueList: any[];
    @Prop() workerId: number;
    @Prop() agentId: number;
    @Prop() sessionId: string;
    @Prop() dialogueHistoryList: any[];

    isWorkerRunning = false;
    currentWorkerId = null;
    dialogueList = [];
    message = '';
    beBeingSent = false;
    selectDialogueIndex = null;

    $refs: {
        scrollMark: HTMLDivElement;
    };

    transSaveData() {
        return {
            dialogueHistoryList: this.dialogueList,
        };
    }

    getRole(role) {
        return roleMap[role];
    }

    transJsonViewerValue(data: string) {
        let obj = {};
        try {
            obj = JSON.parse(data);
        } catch (error) {
            obj = null;
        }
        return obj;
    }

    isNewDialog = true;
    currentGenerate = null;
    controller: AbortController | null = null;
    async chat(params) {
        try {
            this.beBeingSent = true;
            this.controller = new AbortController();
            const res = await fetch('https://model.sankuai.com/external/chat/agentGenerate', {
                method: 'POST',
                body: JSON.stringify(params),
                headers: { 'Content-Type': 'application/json' },
                signal: this.controller.signal,
            });
            const reader = await res.body.getReader();
            let isDone = false;
            let lastRoundData = '';
            while (!isDone) {
                const { done, value } = await reader.read();
                isDone = done;
                if (done) {
                    return false;
                }
                const data = lastRoundData + new TextDecoder().decode(value);
                const responseMessageList = data.split('data:');
                let result = null;
                try {
                    const newDialog = responseMessageList.find((r) => r.includes('finish_reason'));
                    if (newDialog) {
                        result = JSON.parse(newDialog.replace(/\n\n$/, ''));
                        this.renderMessage(result);
                    }
                    if (newDialog === responseMessageList[responseMessageList.length - 1]) {
                        return false;
                    }
                    result = JSON.parse(responseMessageList[responseMessageList.length - 1].replace(/\n\n$/, ''));
                } catch (e1) {
                    try {
                        lastRoundData = responseMessageList[responseMessageList.length - 1].replace(/\n\n$/, '');
                        result = JSON.parse(responseMessageList[responseMessageList.length - 2].replace(/\n\n$/, ''));
                    } catch (e2) {
                        console.log('JSON解析失败', e1, e2, responseMessageList);
                    }
                }
                this.renderMessage(result);
            }
        } catch (error) {
            console.log('获取失败', error);
        } finally {
            this.beBeingSent = false;
            this.currentGenerate = null;
            this.controller = null;
            setTimeout(() => {
                this.scrollBottom();
            }, 300);
        }
    }

    renderMessage(result) {
        console.log(result);
        if (result.finish_reason && result.finish_reason !== 'stop') {
            this.isNewDialog = true;
            this.$message.error(result.result || result.content);
            return false;
        }
        if (this.isNewDialog) {
            this.isNewDialog = false;
            this.dialogueList.push({
                originContent: result.content,
                content: result.content,
                role: result.role.toUpperCase(),
                isUpdate: false,
            });
        } else {
            this.dialogueList[this.dialogueList.length - 1].originContent = result.content;
            this.dialogueList[this.dialogueList.length - 1].content = result.content;
        }
        this.currentGenerate = this.dialogueList.length - 1;
        if (result.finish_reason === 'stop') {
            this.isNewDialog = true;
        }
        this.scrollBottom();
    }

    handleStopGenerate() {
        this.controller && this.controller.abort();
    }

    async handleSendMessage() {
        if (!this.message.trim()) {
            this.$message.warning('请输入内容！');
            return false;
        }

        if (this.beBeingSent) {
            this.$message.warning('请稍后');
            return false;
        }

        this.dialogueList.push({
            content: this.message,
            role: 'USER',
        });
        this.scrollBottom();
        const params = {
            workerId: this.currentWorkerId,
            agentId: this.agentId,
            sessionId: this.sessionId,
            prompts: this.dialogueList.map((d) => ({
                role: d.role,
                content: d.content,
            })),
        };
        this.message = '';
        this.chat(params);
    }

    handleEnter(e) {
        if ([8, 13].includes(e.keyCode)) {
            if (!this.message) {
                return false;
            }
            e.preventDefault();
            this.handleSendMessage();
        }
    }

    scrollBottom() {
        setTimeout(() => {
            this.$refs.scrollMark?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        });
    }

    handleEditContent(editContentIndex, message) {
        this.currentGenerate = editContentIndex;
        editContentDialog(message)
            .then((editMsg) => {
                this.dialogueList[editContentIndex].content = editMsg;
                this.dialogueList[editContentIndex].isUpdate = this.dialogueList[editContentIndex].originContent !== editMsg;
                this.$forceUpdate();
                this.currentGenerate = null;
            })
            .catch(() => {
                //
            });
    }
    handleCarryOut(carryOutIndex) {
        this.$mtd
            .confirm({
                title: '确认信息',
                message: '此操作将清空该条对话之后的对话内容，是否继续编辑。',
                width: '430px',
                showCancelButton: true,
            })
            .then(() => {
                this.dialogueList = this.dialogueList.slice(0, carryOutIndex + 1);
                const params = {
                    workerId: this.currentWorkerId,
                    agentId: this.agentId,
                    sessionId: this.sessionId,
                    prompts: this.dialogueList.map((d) => ({
                        role: d.role,
                        content: d.content,
                    })),
                };
                this.chat(params);
            })
            .catch(() => {
                //
            });
    }

    handleBeforeAdd(index) {
        addDialog()
            .then((data) => {
                this.dialogueList.splice(index, 0, data);
            })
            .catch((e) => {
                //
            });
    }
    handleAfterDel(index) {
        addDialog()
            .then((data) => {
                this.dialogueList.splice(index + 1, 0, data);
            })
            .catch((e) => {
                //
            });
    }

    handleDel(index) {
        this.$mtd
            .confirm({
                title: '确认信息',
                message: '此操作将删除该条对话内容，是否确认。',
                width: '430px',
                showCancelButton: true,
            })
            .then(() => {
                this.dialogueList.splice(index, 1);
            })
            .catch(() => {
                //
            });
    }

    async getWorker() {
        try {
            const { data } = await getWorkerId(this.workerId);
            if (data.status !== 'RUNNING') {
                this.isWorkerRunning = false;
                this.$message.warning('模型还未启动，请先启动配置文件对应的模型。');
                return false;
            }
            this.isWorkerRunning = true;
            this.currentWorkerId = data.workerId;
        } catch (error) {
            console.log('获取workerId失败');
        }
    }

    init() {
        this.getWorker();
        if (this.dialogueHistoryList) {
            this.dialogueList = cloneDeep(this.dialogueHistoryList);
            return false;
        }
        if (this.defaultDialogueList.length) {
            this.dialogueList = this.defaultDialogueList.map((d) => ({
                originContent: d.content,
                ...d,
                isUpdate: false,
            }));
        }
    }

    mounted() {
        this.init();
    }
}

import { importAll, ImportAllResult } from '../../utils/import-all';

const modules: ImportAllResult[] = importAll(require.context('../template', true, /index\.vue$/), /^\.\/([^\s]+)\.vue$/);

const { tplComponents, tplListComponents }: { tplComponents: any; tplListComponents: any } = modules.reduce(
    (obj, item) => {
        if (item.camelModuleName.includes('index')) {
            item.moduleName = item.moduleName.replace('/index', '').replace('tpl-', '');
            item.camelModuleName = item.moduleName.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
            obj.tplComponents[item.camelModuleName] = item.module;
        }
        if (item.camelModuleName.includes('listIndex')) {
            item.moduleName = item.moduleName.replace('/list-index', '').replace('tpl-', '');
            item.camelModuleName = item.moduleName.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
            obj.tplListComponents[item.camelModuleName] = item.module;
        }
        return obj;
    },
    { tplComponents: {}, tplListComponents: {} }
);

const tplComponentKeys = Object.keys(tplComponents);
const tplListComponentKeys = Object.keys(tplListComponents);
export { tplComponents, tplComponentKeys, tplListComponents, tplListComponentKeys };

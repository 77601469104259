




























import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import ChangePassword from './login/changePassword.vue';
import Cookies from 'js-cookie';
let routerArr: any[] = routes.filter((_: any) => _.path === '/')[0].children;
import { refresh } from '@/api/http';

let sideBarData = routerArr.map((i, idx) => {
    if (i.hid) {
        return {};
    }
    return {
        id: i.path,
        title: i.meta.title || '无名称路由',
        // route: i.path,
        icon: i.meta.icon || 'mtdicon-menus-o',
        children: i.children,
        link: i.meta.link,
    };
});

@Component({
    components: { ChangePassword },
})
export default class Layout extends Vue {
    $refs: { modal: any };
    title = '众测平台';
    userInfo = {
        userName: 'XXX',
        userMis: 'xxxxxx',
    };
    sideBarData = sideBarData;
    platform = '';
    watermark = '';
    get activeKey() {
        return this.$route.name;
    }

    get user() {
        return localStorage.getItem('User_Info');
    }
    handleItemClick({ key, item }) {
        if (item.id === '/task-list') {
            this.$router.push('/task-list');
        } else {
            let query = this.$route.query;
            this.$router.push({
                path: item.id,
                query: query,
            });
        }
    }
    showPasswordModal() {
        this.$refs.modal.showModal();
    }

    exitPlatform() {
        this.$api.common.logout({}).then((res) => {
            localStorage.removeItem('User_Info');
            Cookies.remove('cmark_pub_token');
            refresh();
            this.$router.push('/login');
        });
    }

    changePassword() {
        localStorage.removeItem('User_Info');
        Cookies.remove('cmark_pub_token');
        refresh();
        this.$router.push('/login');
    }
    async mounted() {
        this.platform = Cookies.get('cmark_pub_platform');
        document.title = this.platform === '2' ? '通慧chat' : '众测平台';
        this.observe();
        let res = await this.$store.dispatch('getWaterMark');
        this.watermark = window.URL.createObjectURL(res);
        (
            document.getElementsByClassName('watermark')[0] as HTMLBodyElement
        ).style.cssText = `background-repeat: repeat;background-image:url(${this.watermark});`;
    }
    observer: any;
    observe() {
        // 选择目标节点
        let target = document.getElementsByClassName('watermark')[0];

        // 配置观察选项:
        const config = { attributes: true, childList: true, characterData: true };

        // 当观察到变动时执行的回调函数
        let that = this;
        const callback = function (mutationsList, observer) {
            // Use traditional 'for loops' for IE 11
            for (let mutation of mutationsList) {
                console.log(mutation);
                if (mutation.type === 'childList') {
                    console.log('A child node has been added or removed.');
                } else if (mutation.type === 'attributes') {
                    console.log('The ' + mutation.attributeName + ' attribute was modified.');
                    let newDiv = document.createElement('div');
                    newDiv.style.cssText = `background-repeat: repeat;background-image:url(${this.watermark});`;
                    let sideBar = document.getElementsByClassName('side-bar')[0];
                    sideBar.appendChild(newDiv);
                }
            }
        };
        // 创建一个观察器实例并传入回调函数
        this.observer = new MutationObserver(callback);
        // 传入目标节点和观察选项
        this.observer.observe(target, config);
    }
    beforeDestory() {
        // 停止观察
        this.observer.disconnect();
    }
}































import { Vue, Prop, Component } from '@/decorators';
import ChangePassword from './changePassword.vue';
import Cookies from 'js-cookie';
import { refresh } from '@/api/http';

@Component({
    components: { ChangePassword },
})
export default class Login extends Vue {
    $refs: { form: any; modal: any };
    formData = {
        loginName: '',
        password: '',
        captcha: '',
    };
    rules = {
        loginName: [{ required: true, trigger: 'change', validator: this.loginNameValid }],
        password: [{ required: true, trigger: 'change', message: '请输入密码' }],
        captcha: [{ required: true, trigger: 'change', message: '请输入验证码' }],
    };
    captchaPic = '';
    loginNameValid(rule, value, callback) {
        if (!value) {
            return callback(new Error('请输入手机号'));
        }
        let arr = value.split('');
        if (arr.some((i) => isNaN(Number(i)))) {
            return callback(new Error('请输入格式正确的手机号'));
        }
        if (arr.length !== 11) {
            return callback(new Error('请输入完整的手机号'));
        }
        return callback();
    }
    async mounted() {
        let res = await this.$store.dispatch('getCaptchaPicture');
        this.imgUrl = window.URL.createObjectURL(res);
        console.log(window.location.origin);
    }

    imgUrl: any = '';
    // agreeCheck = false;
    modalShow = false;

    loginIn() {
        this.$refs.form.validate(async (valid) => {
            if (valid) {
                let status = await this.$store.dispatch('loginByMobile', this.formData);
                if (status == 1) {
                    // this.$router.push('/task-list');
                } else if (status == 2) {
                    this.$refs.modal.showModal();
                } else if (status === 500) {
                    await this.refreshCaptcha();
                    this.formData.captcha = '';
                }
            }
        });
    }

    changePassword() {
        localStorage.removeItem('User_Info');
        Cookies.remove('cmark_pub_token');
        refresh();
        this.refreshCaptcha();
        this.formData = {
            loginName: '',
            password: '',
            captcha: '',
        };
    }

    async refreshCaptcha() {
        let res = await this.$store.dispatch('getCaptchaPicture');
        this.imgUrl = window.URL.createObjectURL(res);
    }
}














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import DynamicForm from '@/components/dynamic-components/DynamicForm.vue';
import DynamicContent from '@/components/dynamic-components/DynamicContent.vue';
import getCategories, { getAllCategories } from './getCategories';

@Component({
    components: {
        DynamicForm,
        DynamicContent,
    },
})
export default class ContentArea extends Vue {
    @Prop() isShowFitting!: boolean;
    @Prop() isShowContentArea!: boolean;
    @Prop() taskConfig!: any;
    @Prop() caseData!: any;
    @Prop() contentData!: any;
    @Prop() annotationFormData!: any;
    @Prop() loopAnnotationFormData!: any;
    @Prop() loopContentData!: any;

    cascaderParentIdChildrenMap: any = {};
    handledTaskConfigContentArea: any = [];

    $refs: {
        dynamicContent: DynamicContent[];
        dynamicForms: DynamicForm[];
    };

    getDialogueData() {
        const res = this.$refs.dynamicContent.find((d) => d.getDialogueData);
        return res?.getDialogueData();
    }

    getImgAnnotation() {
        const res = this.$refs.dynamicContent?.find((d) => d.getImgAnnotation);
        return res?.getImgAnnotation();
    }

    getVoiceMarkInterval() {
        const res = this.$refs.dynamicContent?.find((d) => d.getVoiceMarkInterval);
        return res?.getVoiceMarkInterval();
    }

    getUnderlineWordData() {
        const res = this.$refs.dynamicContent?.find((d) => d.getUnderlineWordData);
        return res?.getUnderlineWordData();
    }

    onCascaderChange(params) {
        this.$emit('onCascaderChange', params);
    }

    getHandledTaskConfigContentArea() {
        let contArea = this?.taskConfig?.contentArea;
        let contAreaCopy = JSON.parse(JSON.stringify(contArea));

        contAreaCopy.forEach((ca) => {
            let formItemList = [];
            if (ca.areaConfig) {
                formItemList = ca.areaConfig.formItemList || [];
            } else if (ca.content && Array.isArray(ca.content)) {
                ca.content.forEach((contentItem) => {
                    let a = contentItem.areaConfig?.formItemList || [];
                    formItemList.push(...a);
                });
            }
            formItemList.forEach((fi) => {
                if (fi?.formItemType === 'el-cascader' && fi?.isModelCategory) {
                    fi.attr.data = this.transCascaderList;
                    // let firstGradeNodes = fi.attr.data;
                    // console.log('firstGradeNodes', firstGradeNodes, this.cascaderParentIdChildrenMap);
                    // let firstGradeHaveChildrenNodes = firstGradeNodes.filter(n => this.cascaderParentIdChildrenMap[n.id]);
                    // firstGradeHaveChildrenNodes.forEach(n => this.generateAllChildrenByNode(n));
                }
            });
        });
        return contArea ? contAreaCopy : [];
    }

    generateAllChildrenByNode(node) {
        let children = this.cascaderParentIdChildrenMap[node.id];
        node.children = children.map((c) => ({ label: c.name, value: c.name, id: c.id }));
        node.children.forEach((c) => {
            let v = this.cascaderParentIdChildrenMap[c.id];
            if (v) {
                this.generateAllChildrenByNode(c);
            }
        });
    }

    get handledMultiFixResult() {
        let txt = this.caseData.multiFixResult;
        let style: any = {};
        let isSame = true;
        if (!txt) return false;
        if (txt.includes('拟合不一致')) {
            style.backgroundColor = '#fef6f0';
            style.color = '#94410a';
            isSame = false;
        } else {
            style.backgroundColor = '#eef4fd';
            style.color = '#2476f7';
        }
        return { txt, style, isSame };
    }

    async getCascaderChildren(id) {
        return await getCategories(id).then(async (res) => {
            let responseStr = res?.data?.data?.response;
            let curCascaderItemChildren = responseStr ? JSON.parse(responseStr) : [];
            this.cascaderParentIdChildrenMap[id] = curCascaderItemChildren;
            console.log('curCascaderItemChildren', curCascaderItemChildren);
            let haveChildrenNodeIds = curCascaderItemChildren.filter((ci) => ci.isLeaf === 0).map((ci) => ci.id);
            for (let i = 0; i < haveChildrenNodeIds.length; i++) {
                await this.getCascaderChildren(haveChildrenNodeIds[i]);
            }
        });
    }

    initCascaderChidrenIds = [];
    async getInitCascaderChildren() {
        let contArea = this?.taskConfig?.contentArea;
        if (!contArea) return false;
        this.initCascaderChidrenIds = contArea
            .filter((ca) => ca.type === 'annotationForm')
            .map((af) => af.areaConfig)
            .reduce((acc, cur) => acc.concat(cur.formItemList), [])
            .filter((fi) => fi.formItemType === 'el-cascader' && fi.isModelCategory)
            .reduce((acc, cur) => acc.concat(cur?.attr?.data || []), [])
            .map((cascItem) => cascItem.id);
        // 去重
        this.initCascaderChidrenIds = Array.from(new Set(this.initCascaderChidrenIds));
        if (this.initCascaderChidrenIds.length) {
            await this.getInitCascader();
            return false;
        }

        this.handledTaskConfigContentArea = this.getHandledTaskConfigContentArea();
        // let allRequests = initCascaderChidrenIds.map(id => this.getCascaderChildren(id));
        // Promise.all(allRequests).then(() => {
        //     console.log('this.cascaderParentIdChildrenMap', Object.keys(this.cascaderParentIdChildrenMap));
        //     this.handledTaskConfigContentArea = this.getHandledTaskConfigContentArea();
        // });
    }

    cascaderList = [];
    transCascaderList = [];
    pageNum = 1;
    async getInitCascader() {
        try {
            const { data } = await getAllCategories(this.pageNum);
            const result = JSON.parse(data.data.response);
            const list = result.list.map((l) => ({
                id: l.id,
                label: l.name,
                value: l.name,
                parentId: l.parentId,
            }));
            this.cascaderList = [...this.cascaderList, ...list];
            if (this.pageNum * 500 < result.total) {
                this.pageNum++;
                this.getInitCascader();
            } else {
                const topLevelId = this.cascaderList.find((c) => c.parentId === -1)?.id;
                if (topLevelId) {
                    this.cascaderList = this.cascaderList.filter((c) => c.parentId !== -1);
                    this.transCascaderList = this.transList(topLevelId).filter((t) => this.initCascaderChidrenIds.includes(t.id));
                    this.handledTaskConfigContentArea = this.getHandledTaskConfigContentArea();
                }
            }
        } catch (error) {
            console.log('获取失败');
        }
    }

    transList(pid: number) {
        const arr = [];
        this.cascaderList.forEach((item) => {
            if (item.parentId === pid) {
                const children = this.transList(item.id);
                if (children.length) {
                    item.children = children;
                }
                arr.push(item);
            }
        });
        return arr;
    }

    mounted() {
        this.getInitCascaderChildren();
    }
}

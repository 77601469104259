





















import { Vue, Component } from 'vue-property-decorator';
@Component
export default class EditContent extends Vue {
    show = false;
    message = '';

    handleSubmit() {
        if (!this.message.trim()) {
            this.$message.warning('请输入内容！');
            return false;
        }
        this.$emit('success', this.message);
        this.show = false;
    }

    handleCancel() {
        this.show = false;
        this.$emit('cancel');
    }

    handleClosed() {
        this.$destroy();
    }
}

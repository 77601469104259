import EditContent from './index.vue';

export default function (message: string) {
    const instance: EditContent & { show: boolean; message: string } = new EditContent();

    instance.message = message;
    instance.$mount();
    instance.show = true;

    return new Promise<any[]>((resolve, reject) => {
        instance.$on('success', resolve);
        instance.$on('cancel', reject);
    });
}

import { importAll, ImportAllResult } from '@/utils/import-all';

const schemaModules: ImportAllResult[] = importAll(require.context('./dynamic-form-item-schema', true, /\.ts$/));

const DynamicFormItemSchemas = schemaModules.reduce((components: any, { module, moduleName }) => {
    components[moduleName] = module;
    return components;
}, {});

const formItemModules: ImportAllResult[] = importAll(require.context('./dynamic-form-item', true, /\.vue$/), /^\.\/([^\s]+)\.vue$/);
const DynamicFormItems = formItemModules.reduce((components: any, { module, moduleName }) => {
    components[moduleName] = module;
    return components;
}, {});

export { DynamicFormItemSchemas, DynamicFormItems };

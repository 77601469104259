import Vue from 'vue';
import Vuex from 'vuex';
import { importAll, ImportAllResult } from '@/utils';

// Registered store modules
const storeModules = importAll(require.context('@/store/modules', true, /index\.ts$/));
const modules = storeModules.reduce((finalModules: any, storeModule: ImportAllResult) => {
    finalModules[storeModule.camelModuleName.split('/')[0]] = storeModule.module;
    return finalModules;
}, {});

Vue.use(Vuex);

const $store = new Vuex.Store({
    modules,
});
window['$store'] = $store;
export default $store;











import { Vue, Prop, Component, Watch } from '@/decorators';
import QRCode from 'qrcode';

@Component
export default class CreateQrcode extends Vue {
    @Prop({ default: 'default text' })
    content;
    @Prop({ default: 0 })
    idx;

    canvas: any = '';
    coverShow = false;

    mounted() {
        this.canvas = document.getElementById(`qrcode${this.idx}`);
        this.createQrcode(this.content);
    }
    createQrcode(content) {
        this.coverShow = false;
        QRCode.toCanvas(this.canvas, content, (error) => {
            if (error) {
                console.log(error);
            }
        });
    }
    clearQrcode(valid) {
        let validTime = valid;
        setTimeout(() => {
            let cxt = document.getElementById('qrcode');
            let w = cxt.style.width;
            let h = cxt.style.height;
            cxt.style.width = w;
            cxt.style.height = h;
            this.coverShow = true;
            clearTimeout();
        }, validTime);
    }
}

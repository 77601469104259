






import { Component, Vue } from 'vue-property-decorator';
import ChatWindow from '@/components/ChatWindow.vue';

@Component({
    components: {
        ChatWindow,
    },
})
export default class Home extends Vue {}
















import { Component, Vue } from 'vue-property-decorator';
import { tplListComponents, tplComponentKeys } from '@/views/template';

@Component({
    components: {
        ...tplListComponents,
    },
})
export default class Home extends Vue {
    get validTplComponent() {
        let { taskId, taskType } = this.$route.query;
        return tplComponentKeys.includes(taskType as string) && taskType;
    }
}

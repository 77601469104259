import axios from 'axios';
import { getAuthHeaders } from '@/utils/header';
import queryString from 'query-string';

export default async function getCategories(parentId?: number) {
    let authHeader = getAuthHeaders();
    let customHeader = {
        'Content-Type': 'application/json',
        'X-NlpOpen-User': 'wb_muyingjie',
        'X-NlpOpen-Token': '49b3d1b9-0d84-4a0c-8453-5e3c228d5482',
    };

    let queryObj: any = {
        dimId: 6,
    };
    if (parentId) {
        queryObj.parentId = parentId;
    }
    let qs = queryString.stringify(queryObj);
    let env = process.env.CONFIG_ENV || 'test';

    let urlMap = {
        test: {
            modelUrl: 'https://model.inf.test.sankuai.com',
            url: 'https://annotation.inf.test.sankuai.com',
        },
        staging: {
            modelUrl: 'https://model.inf.st.sankuai.com',
            url: 'https://llmmark.sankuai.com',
        },
        production: {
            modelUrl: 'https://model.sankuai.com',
            url: 'https://brain.meituan.com',
        },
    };
    return await axios.post(
        `${urlMap[env].url}/query/annotation/common/v1/httpGetProxy`,
        { url: `${urlMap[env].modelUrl}/webApi/category/categories?${qs}`, headers: customHeader },
        { headers: authHeader }
    );
}

export async function getAllCategories(pageNum = 1) {
    let authHeader = getAuthHeaders();
    let customHeader = {
        'Content-Type': 'application/json',
        'X-NlpOpen-User': 'wb_muyingjie',
        'X-NlpOpen-Token': '49b3d1b9-0d84-4a0c-8453-5e3c228d5482',
    };

    const queryObj: any = {
        dimId: 6,
        pageSize: 500,
        pageNum,
    };
    const qs = queryString.stringify(queryObj);
    const env = process.env.CONFIG_ENV || 'test';

    const urlMap = {
        test: {
            modelUrl: 'https://model.inf.test.sankuai.com',
            url: 'https://annotation.inf.test.sankuai.com',
        },
        staging: {
            modelUrl: 'https://model.inf.st.sankuai.com',
            url: 'https://llmmark.sankuai.com',
        },
        production: {
            modelUrl: 'https://model.sankuai.com',
            url: 'https://brain.meituan.com',
        },
    };

    return await axios.post(
        `${urlMap[env].url}/query/annotation/common/v1/httpGetProxy`,
        { url: `${urlMap[env].modelUrl}/webApi/category/queryCategorys?${qs}`, headers: customHeader },
        { headers: authHeader }
    );
}
































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class AnnotationReworkTip extends Vue {
    @Prop({ type: Object, default: {} })
    caseData!: any;

    formatSuggestInfo(suggestInfo) {
        if (suggestInfo && typeof suggestInfo === 'string') {
            suggestInfo = JSON.parse(suggestInfo);
            if (suggestInfo.annotationResultCheck && typeof suggestInfo.annotationResultCheck === 'string') {
                suggestInfo.annotationResultCheck = JSON.parse(suggestInfo.annotationResultCheck);
            }
            return suggestInfo;
        } else if (suggestInfo) {
            return suggestInfo;
        } else {
            this.$mtd.message.error('suggestInfo数据不存在');
            return { tip: 'suggestInfo数据不存在' };
        }
    }
}

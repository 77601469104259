






















import { Vue, Component, Prop } from 'vue-property-decorator';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

@Component
export default class VoiceAnnotation extends Vue {
    @Prop() voice: string;
    @Prop() voiceMarkIntervalData: any[];

    isInitData = false;
    loading = false;
    loadingMessage = '';
    waveSurfer = null;
    wsRegions = null;
    activeRegion = null;
    speed = '1.0';
    zoom = 0;
    speedList = ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2.0'];
    selectAutoPlay = true;

    $refs: {
        voiceDom: HTMLDivElement;
    };

    get getVoiceMarkInterval() {
        return this.wsRegions?.regions.map((w) => ({
            start: w.start,
            end: w.end,
        }));
    }

    handlePlayPause() {
        this.waveSurfer.playPause();
    }

    handleChangeSpeed() {
        this.waveSurfer.setPlaybackRate(Number(this.speed), true);
    }

    handleBlowUp() {
        if (this.zoom >= 400) {
            return false;
        }
        this.zoom += 20;
        this.waveSurfer.zoom(this.zoom);
    }
    handleMinification() {
        if (this.zoom <= 0) {
            return false;
        }
        this.zoom -= 20;
        this.waveSurfer.zoom(this.zoom);
    }

    isShowWaveForm = true;
    handleShowAndCloseWaveForm() {
        this.isShowWaveForm = !this.isShowWaveForm;
        this.$refs.voiceDom.style.visibility = this.isShowWaveForm ? 'visible' : 'hidden';
    }

    get regionLen() {
        return this.wsRegions?.regions.length || 0;
    }

    createAndUpdateRegion(region) {
        const indexDom = region.element.querySelector('.index');
        if (indexDom) {
            indexDom.innerHTML = region.id;
            region.element.querySelector('.start').innerHTML = region.start.toFixed(2);
            region.element.querySelector('.end').innerHTML = region.end.toFixed(2);
        } else {
            // 在这里添加动态的内容
            const contentElement = document.createElement('div');
            contentElement.className = 'index';
            contentElement.textContent = region.id;
            // 将这个元素添加到区间元素中
            region.element.appendChild(contentElement);
            const deleteElement = document.createElement('div');
            deleteElement.innerHTML = 'X';
            deleteElement.onclick = (e) => {
                e.stopPropagation();
                region.remove();
            };
            deleteElement.style.cssText = 'width: fit-content; position: absolute; right: 4px; top: 0px; font-size: 18px; cursor: pointer;';
            region.element.appendChild(deleteElement);
            const timeElement = document.createElement('div');
            timeElement.style.cssText = 'width: 100%; display: flex; justify-content: space-between; position: absolute; bottom: 30px;';
            timeElement.innerHTML = `<span class="start">${region.start.toFixed(2)}</span><span class="end">${region.end.toFixed(
                2
            )}</span>`;
            region.element.appendChild(timeElement);
        }

        if (this.isInitData) {
            return false;
        }

        if (this.selectAutoPlay) {
            this.waveSurfer.setTime(region.start);
            this.waveSurfer.play();
            setTimeout(() => {
                this.activeRegion = `${region.start}-${region.end}`;
            }, 100);
        }
    }

    mounted() {
        this.loading = true;
        this.waveSurfer = WaveSurfer.create({
            container: this.$refs.voiceDom,
            waveColor: '#800080',
            progressColor: '#ee82ee',
            url: this.voice,
            plugins: [TimelinePlugin.create()],
        });
        this.waveSurfer.on('decode', () => {
            this.waveSurfer.zoom(this.zoom);
            if (this.voiceMarkIntervalData) {
                this.isInitData = true;
                this.voiceMarkIntervalData.forEach((voice) => {
                    this.wsRegions.addRegion({
                        start: voice.start,
                        end: voice.end,
                    });
                });
                this.isInitData = false;
            }
        });
        this.waveSurfer.on('loading', (percent) => {
            this.loading = percent !== 100;
            this.loadingMessage = `正在加载中，${percent}%`;
        });
        this.waveSurfer.on('click', () => {
            this.activeRegion = null;
            this.waveSurfer.play();
        });

        this.wsRegions = this.waveSurfer.registerPlugin(RegionsPlugin.create());

        this.wsRegions.enableDragSelection({});
        this.wsRegions.on('region-created', (region) => {
            this.createAndUpdateRegion(region);
        });
        this.wsRegions.on('region-updated', (region) => {
            const index = this.wsRegions.regions.findIndex((w) => w === region);
            this.createAndUpdateRegion(region);
        });

        this.wsRegions.on('region-out', (region) => {
            if (this.activeRegion === `${region.start}-${region.end}`) {
                this.waveSurfer.pause();
            }
        });
        this.wsRegions.on('interaction', () => {
            this.activeRegion = null;
        });
    }
}

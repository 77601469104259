import { factory } from '@ai/flow-http';
// import sso from config directly would cause circular dependency
import { Notification } from '@ss/mtd-vue';
import qs from 'qs';
import Cookies from 'js-cookie';
import { ENV } from '@/config';

const isProd = process.env.CONFIG_ENV === 'production';

const config: any = () => {
    return {
        baseURL: ENV.Baseurl,
        notifyMessage: Notification.error,
        headers: {
            CmarkPubToken: Cookies.get('cmark_pub_token'),
        },
        withCredentials: true,
        development: {
            baseURL: 'http://cmark.pub.adp.test.sankuai.com/',
        },
        validateResponse(data) {
            return data.rescode == 0;
        },
        extractData: false,
        paramsSerializer: function (params: any) {
            return qs.stringify(params);
        },
        extractErrorMessage(res: any) {
            return res.rescode + '：' + res.data.message;
        },
        rethrowError: true,
        isUnauthorized(res) {
            if (res.data.rescode == 403) {
                Notification.error('登陆态失效或授权失败');
            }
            return res.data.rescode == 403;
        },
        loginUrl: isProd ? window.location.origin + '/login' : window.location.origin + '/mark/login',
        // loginUrl: window.location.origin + '/mark/login',
    };
};

let http = factory(config());

function refresh() {
    http = factory(config());
}
export { http, refresh };

































import { Vue, Prop, Component } from '@/decorators';
import { categoryList } from '../helper/constant';
import Cookies from 'js-cookie';

@Component
export default class UploadFile extends Vue {
    imageUrl = '';
    categoryList = categoryList;
    hasUpload = false;
    uploadFail;
    user = localStorage.getItem('User_Info');
    headers = {
        CmarkPubToken: Cookies.get('cmark_pub_token'),
    };
    textList = {
        0: '请上传图片',
        1: '二维码已过期，请刷新标注界面获取最新二维码',
        2: '发起上传请求失败',
        3: '上传图片大小不能超过 10MB',
    };
    textStatus = 0;

    clearData() {
        this.imageUrl = '';
        this.hasUpload = false;
        this.textStatus = 0;
    }
    get details() {
        return this.$route.query;
    }

    handleAvatarError(e, file) {
        this.textStatus = 2;
    }
    handleAvatarSuccess(res, file) {
        if (res.data?.message == '二维码已过期') this.textStatus = 1;
        else if (res.rescode == 0) this.textStatus = 0;
        else this.textStatus = 2;

        if (this.textStatus == 0) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.hasUpload = true;
        }
    }
    beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            this.textStatus = 3;
        }
        return isLt2M;
    }
    getUrl() {
        let url = `${window.location.origin}/query/annotation/anno/v1/uploadCasePic?picKey=${this.details.picKey}`;
        return url;
    }
}

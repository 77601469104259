








































































import { Component, Prop, Vue, Watch } from '@/decorators';
import JsonBox from '@/components/json-box.vue';

const statusCode = {
    0: '未分配数量',
    1: '标注中未暂存',
    2: '已暂存未提交',
    99: '标注完成待质检',
    4: '重新标注',
    5: '质检中',
    11: '有问题待处理(删除case)',
    80: '等待复检',
    81: '复检中',
    100: '美团质检完成',
};

@Component({
    components: { JsonBox },
})
export default class AnnotationDevTip extends Vue {
    @Prop({ default: () => ({}) })
    caseDetail;
    dashboardData = {};
    statusCountArray = [];
    detailValue = false;
    keyMap = {
        ...statusCode,
        caseProgress: '任务进度',
        correctRate: '标注项正确率',
    };
    userAnnotationStatus: any = {
        1: 0,
        2: 0,
        99: 0,
    };
    taskAnnotationStatus: any = {
        1: 0,
        2: 0,
        99: 0,
        caseProgress: 0,
    };

    @Watch('caseDetail', { immediate: true })
    handleCaseDetailChange() {
        // 重置标注额外信息
        this.statusCountArray = [];
        if (this.detailValue) {
            this.getStatus();
        }
    }

    valueChange() {
        if (this.statusCountArray.length !== 0) return;
        this.getStatus();
    }

    async getStatus() {
        const { annotationUser } = this.caseDetail;
        let res = await this.$api.common.getTaskDashboardData({ taskId: this.caseDetail.taskId });
        let data = res.data;
        if (data) {
            const { statusCountArray, taskTotalCount } = data;
            this.statusCountArray = statusCountArray;
            this.statusCountArray.forEach(({ statusCode, statusTotalCount, userCountArray }) => {
                if (this.taskAnnotationStatus[statusCode] !== undefined) {
                    this.taskAnnotationStatus[statusCode] = statusTotalCount;
                }
                if (this.userAnnotationStatus[statusCode] !== undefined) {
                    const user = userCountArray.find((item) => item.userMis === annotationUser);
                    this.userAnnotationStatus[statusCode] = user?.count || 0;
                }
            });
            this.taskAnnotationStatus.caseProgress =
                (((this.taskAnnotationStatus[2] + this.taskAnnotationStatus[99]) / taskTotalCount) * 100).toFixed(2) + '%';
        }
    }

    copyUrl() {
        let { taskId, caseId } = this.caseDetail;
        const isProd = process.env.CONFIG_ENV === 'production';
        let url = `${window.location.origin}/mark/task-work?taskId=${taskId}&caseId=${caseId}`;
        if (isProd) {
            url = `${window.location.origin}/task-work?taskId=${taskId}&caseId=${caseId}`;
        }
        this.$copyText(url)
            .then(() => {
                this.$mtd.message.success('复制成功，快发送给开发人员吧');
            })
            .catch((err) => {
                console.log(err);
                // this.$mtd.message.error('复制错误，您没有权限');
            });
    }
}

import Vue from 'vue';
import { VueConstructor } from 'vue/types/umd';
import api from '@/api';
import * as model from '@/model';
import * as filters from './filters';

export function serviceVuePlugin(Vue: VueConstructor<Vue>) {
    Vue.prototype.$api = api;

    Vue.prototype.$model = model;

    Vue.prototype.$filters = filters;

    // exact typing for variable key
    (Object.keys(filters) as Array<keyof typeof filters>).forEach((key) => {
        Vue.filter(key, filters[key]);
    });
}

declare module 'vue/types/vue' {
    interface Vue {
        $api: typeof api;
        $filters: typeof filters;
        $model: typeof model;
    }
}




































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import markdownIt from 'markdown-it';
import markdownItTexmath from 'markdown-it-texmath';
import MultiRoundDialogue from './MultiRoundDialogue.vue';
import MultiRoundDialogueSingleWindow from './MultiRoundDialogueSingleWindow.vue';
import VoiceAnnotation from './VoiceAnnotation.vue';
import 'katex/dist/katex.min.css';
import { katex } from './katex';
import MultiRoleWindow from './MultiRoleWindow.vue';
import MultiRoundModelArena from './MultiRoundModelArena/index.vue';
import MultiRoundDialogOverlay from './MultiRoundDialogOverlay.vue';
import ImgAnnotation from './ImgAnnotation.vue';
import UnderlineWord from './UnderlineWord.vue';

@Component({
    components: {
        MultiRoundDialogue,
        MultiRoundDialogueSingleWindow,
        MultiRoleWindow,
        VoiceAnnotation,
        MultiRoundModelArena,
        MultiRoundDialogOverlay,
        ImgAnnotation,
        UnderlineWord,
    },
})
export default class DynamicContent extends Vue {
    @Prop() caseData?: any;
    @Prop({ default: false })
    editMode: boolean;
    @Prop()
    contentConfig: any;
    @Prop({ default: () => ({}) })
    contentData: any;
    @Prop({ default: false })
    useDefaultContentData: boolean;
    defaultImg = '//s3plus.meituan.net/v1/mss_c9d411db85ec4b59aaebc4dfca416c55/production/search-nlp-fe/static/img/clogo.b681817.png';
    activeCollapseValue = [];

    isUseHistory = true;
    showOverlay = false;
    refreshKey = 1;

    imgAnnotationHistory = [];
    underlineWordHistory = [];

    md: any = null;

    toggleOverlay() {
        this.showOverlay = !this.showOverlay;
    }

    show(img) {
        this.$viewerApi({
            images: img,
        });
    }
    filterContent(data, formatLatex) {
        // return formatLatex === 'open'
        //     ? this.md?.render(data.replace(/</g, '&lt').replace(/>/g, '&gt'))
        //     : data.replace(/</g, '&lt').replace(/>/g, '&gt'); // 显示html标签代码片段

        return formatLatex === 'open' ? this.md?.render(data) : data; // 显示html标签代码片段
    }
    linkUrl(item) {
        let url: string = this.contentData[item.contentKey] || (this.useDefaultContentData ? item.contentKey : '');
        if (url.includes('//www.dianping.com/shop/') && !url.includes('utm_source')) {
            url = url.includes('?')
                ? url + '&utm_source=annotation&utm_medium=brain.meituan.com'
                : url + '?utm_source=annotation&utm_medium=brain.meituan.com';
        }
        // 如果是 ugc 详情页需要拼参数
        if (url.includes('/ugcdetail/')) {
            const token = localStorage.getItem('annotation_ssoid');
            url = `${url}${url.includes('?') ? '&' : '?'}ssotoken=${token}`;
        }
        if (url.includes('http://')) {
            url = url.replace('http://', 'https://');
        }
        // 样本未添加协议会导致跳转链接自动携带当前url
        if (url !== '' && !url.includes('https://')) url = 'https://' + url;
        return url;
    }

    $refs: {
        collapseFieldOpenRenderHtml: HTMLDivElement[];
        openRenderHtml: HTMLDivElement[];
        multiRoundDialogue: MultiRoundDialogue[];
        multiRoundDialogueSingleWindow: MultiRoundDialogueSingleWindow[];
        multiRoleWindow: MultiRoleWindow[];
        voiceAnnotation: VoiceAnnotation[];
        multiRoundModelArena: MultiRoundModelArena;
        imgAnnotation: ImgAnnotation[];
        underlineWord: UnderlineWord[];
    };

    handleCancelUseCache() {
        this.showOverlay = false;
        this.isUseHistory = false;
        this.refreshKey++;
        this.clearDialogCache();
    }

    clearDialogCache() {
        const cacheData = localStorage.getItem('_multi_round_dialog_');
        if (cacheData) {
            const data = JSON.parse(cacheData);
            delete data[this.caseData.caseId];
            localStorage.setItem('_multi_round_dialog_', JSON.stringify(data));
        }
    }
    saveCache() {
        const data = this.getDialogueData();
        if (data) {
            const cacheData = localStorage.getItem('_multi_round_dialog_');
            let saveData = null;
            if (cacheData) {
                saveData = JSON.parse(cacheData);
                saveData[this.caseData.caseId] = data;
            } else {
                saveData = {
                    [this.caseData.caseId]: data,
                };
            }
            localStorage.setItem('_multi_round_dialog_', JSON.stringify(saveData));
        }
    }

    get getCacheData() {
        const cacheData = localStorage.getItem('_multi_round_dialog_');
        if (cacheData && this.caseData?.caseId) {
            return JSON.parse(cacheData)[this.caseData.caseId];
        }
        return null;
    }

    getDialogueData() {
        if (this.$refs.multiRoundDialogue) {
            const res = {};
            this.$refs.multiRoundDialogue.forEach((item, index) => {
                res[index] = item.transSaveData()[index];
            });
            return res;
        }
        if (this.$refs.multiRoundDialogueSingleWindow) {
            return this.$refs.multiRoundDialogueSingleWindow?.[0]?.transSaveData();
        }

        if (this.$refs.multiRoleWindow) {
            return this.$refs.multiRoleWindow?.[0]?.transSaveData();
        }
        if (this.$refs.multiRoundModelArena) {
            return this.$refs.multiRoundModelArena?.[0]?.transSaveData();
        }
        return null;
    }

    getImgAnnotation() {
        if (this.$refs.imgAnnotation) {
            return this.$refs.imgAnnotation.map((ia) => {
                return ia.transSaveData();
            });
        }
        return null;
    }

    getVoiceMarkInterval() {
        if (this.$refs.voiceAnnotation) {
            return this.$refs.voiceAnnotation?.map((v) => v.getVoiceMarkInterval);
        }
        return null;
    }

    getUnderlineWordData() {
        if (this.$refs.underlineWord) {
            return this.$refs.underlineWord[0].getData();
        }
        return null;
    }

    get multiRoundDialogueData() {
        if (!this.caseData) {
            return {};
        }
        if (this.getCacheData && this.isUseHistory) {
            return this.getCacheData;
        }
        // 编辑标注显示标注结果
        if (
            this.caseData.annotationResult &&
            this.$route.name === 'annotation-work' &&
            this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            return JSON.parse(this.caseData.annotationResult).dialogueData;
        }

        // 标注界面有标注记录并且有质检记录 return 质检记录
        if (
            this.$route.name === 'annotation-work' &&
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck).dialogueData;
        }
        // 标注界面有标注记录 return 标注记录
        // 其他界面 都return 标注记录
        if (this.caseData.annotationResult || (this.caseData.annotationResult && this.$route.name !== 'annotation-work')) {
            return JSON.parse(this.caseData.annotationResult).dialogueData;
        }
        // 标注界面无标注记录 return null
        return null;
    }

    get voiceMarkIntervalData() {
        if (!this.caseData) {
            return [];
        }
        if (this.caseData.annotationResult && this.caseData.annotationTimeLengthDetail?.annotationTime) {
            return JSON.parse(this.caseData.annotationResult)?.voiceMarkIntervalData || [];
        }

        if (
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck)?.voiceMarkIntervalData || [];
        }

        return [];
    }

    imgAnnotationConfigList(item) {
        if (this.contentData[item.contentKey]) {
            return JSON.parse(this.contentData[item.contentKey]);
        }
        return [this.defaultImg];
    }

    get cImgAnnotation() {
        if (!this.caseData) {
            return [];
        }
        if (this.caseData.annotationResult && this.caseData.annotationTimeLengthDetail?.annotationTime) {
            return JSON.parse(this.caseData.annotationResult)?.imgAnnotation || [];
        }

        if (
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck)?.imgAnnotation || [];
        }
        return [];
    }

    get cUnderlineWordData() {
        if (!this.caseData) {
            return {};
        }
        if (this.caseData.annotationResult && this.caseData.annotationTimeLengthDetail?.annotationTime) {
            return JSON.parse(this.caseData.annotationResult)?.underlineWordData || {};
        }

        if (
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck)?.underlineWordData || {};
        }
        return {};
    }

    get cHistoryAnnotation() {
        if (!this.caseData) {
            return [];
        }
        if (
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck)?.imgHistoryAnnotation || [];
        }
        if (this.caseData.annotationResult && this.caseData.annotationTimeLengthDetail?.annotationTime) {
            return JSON.parse(this.caseData.annotationResult)?.imgHistoryAnnotation || [];
        }
        return [];
    }
    get cUnderlineWordAnnotation() {
        if (!this.caseData) {
            return [];
        }
        if (
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail?.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck)?.underlineWordHistoryData || [];
        }
        if (this.caseData.annotationResult && this.caseData.annotationTimeLengthDetail?.annotationTime) {
            return JSON.parse(this.caseData.annotationResult)?.underlineWordHistoryData || [];
        }
        return [];
    }

    @Watch('contentData', { immediate: true })
    changeContentItemList() {
        this.$nextTick(() => {
            this.$refs.collapseFieldOpenRenderHtml?.forEach((item) => {
                let shadowRoot = item.shadowRoot;
                if (!shadowRoot) {
                    shadowRoot = item?.attachShadow({ mode: 'open' });
                }
                if (!shadowRoot) {
                    return false;
                }
                shadowRoot.innerHTML =
                    item.dataset.format_latex === 'open'
                        ? this.md.render(this.contentData[item.dataset.content_key] || '')
                        : this.contentData[item.dataset.content_key] || '';
                if (item.dataset.format_latex === 'open') {
                    const style = document.createElement('style');
                    style.innerHTML = katex;
                    shadowRoot.appendChild(style);
                }
            });
            this.$refs.openRenderHtml?.forEach((item) => {
                let shadowRoot = item.shadowRoot;
                if (!shadowRoot) {
                    shadowRoot = item?.attachShadow({ mode: 'open' });
                }
                if (!shadowRoot) {
                    return false;
                }

                shadowRoot.innerHTML =
                    item.dataset.format_latex === 'open'
                        ? this.md.render(this.contentData[item.dataset.content_key] || '')
                        : this.contentData[item.dataset.content_key] || '';
                if (item.dataset.format_latex === 'open') {
                    const style = document.createElement('style');
                    style.innerHTML = katex;
                    shadowRoot.appendChild(style);
                }
            });
            // this.$refs.formatLatexHtml?.forEach(item => {
            //     let shadowRoot = item.shadowRoot;
            //     if (!shadowRoot) {
            //         shadowRoot = item?.attachShadow({ mode: 'open' });
            //     }
            //     if (!shadowRoot) {
            //         return false;
            //     }

            //     shadowRoot.innerHTML = this.md.render(this.contentData[item.dataset.content_key] || '');
            //     const style = document.createElement('style');
            //     style.innerHTML = katex;
            //     shadowRoot.appendChild(style);
            // });
        });
    }

    transModels(item) {
        if (!this.caseData) {
            return [];
        }
        const { contentKey, isDoubleBlind } = item;
        let models = this.contentData[contentKey] ? JSON.parse(this.contentData[contentKey]).models : [];
        if (isDoubleBlind === 1 && !this.multiRoundDialogueData) {
            models.sort(() => Math.random() - 0.5);
        }
        if (this.multiRoundDialogueData) {
            models.forEach((m, index) => {
                models[index] = this.multiRoundDialogueData[index].model;
            });
        }
        return models;
    }

    multiWindowData(contentKey) {
        return this.contentData[contentKey] ? JSON.parse(this.contentData[contentKey]) : {};
    }

    created() {
        this.showOverlay = !!this.getCacheData;
    }

    mounted() {
        this.md = markdownIt();
        this.md.use(markdownItTexmath, { delimiters: ['dollars', 'brackets', 'beg_end'] });
    }
}

let taskListTableConfig = {
    itemList: [
        { label: '任务ID', prop: 'taskId' },
        { label: '任务名', prop: 'dataVersion' },
        { label: '任务状态', slotName: 'taskStatus' },
        { label: '任务总数', prop: 'totalCount' },
        { label: '未分配数', prop: 'undistributedCount' },
        { label: '本人已标注数', prop: 'selfAnnoCount' },
        { label: '本人已暂存数', prop: 'selfTempSaveCount' },
        { label: '操作', slotName: 'operate', width: '200px' },
    ],
};
export { taskListTableConfig };

let annotationConfig = ['左侧好', '右侧好', '都好', '都不好'];
export { annotationConfig };

let categoryList = {
    mt: '美团',
    dp: '点评',
    pd: '频道内',
    xy: '新氧',
    xhs: '小红书',
    ele: '饿了么',
    bbs: '宝宝树',
    fb: '58',
    tb: '淘宝',
    jd: '京东',
    fz: '飞猪',
    xc: '携程',
    gd: '高德',
};
export { categoryList };

let TaskStatus = {
    0: '未创建(0)',
    1: '标注中(1)',
    2: '外部质检中(2)',
    3: '美团质检中(3)',
    4: '已完成(4)',
    5: '任务待下发(5)',
};
export { TaskStatus };































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModelDetailItem from './ModelDetailItem.vue';
interface IModel {
    model_id: number;
    temperature: number;
    top_k: number;
    top_p: number;
    frepetition_penalty: number;
    max_tokens: string;
    prompt: string;
    prefix?: string;
    suffix?: string;
}

@Component({
    components: {
        ModelDetailItem,
    },
})
export default class ModelDetail extends Vue {
    @Prop() modelList!: IModel[];
    @Prop() isDoubleBlind!: number;
    @Prop() beBeingSent!: boolean;

    isEdit = false;
    isShowParams = true;
    paramsCollapse = [];

    $refs: {
        modelDetailItem: ModelDetailItem[];
    };

    handleEdit() {
        if (this.beBeingSent) {
            this.$message.warning('请先等待当前当前模型生成结束，再进行操作。');
            return false;
        }
        this.$mtd
            .confirm({
                title: '确认信息',
                message: '编辑模型详情后将重新开始对话，是否继续编辑。',
                width: '430px',
                showCancelButton: true,
            })
            .then(() => {
                this.isEdit = true;
                this.paramsCollapse = Array.from(Array(this.modelList.length).keys());
            })
            .catch(() => {
                //
            });
    }

    handleCancel() {
        this.$refs.modelDetailItem.forEach((item) => {
            item.initParameter();
        });
        this.isEdit = false;
    }

    handleSave() {
        this.$refs.modelDetailItem.forEach((item) => {
            item.handleSave();
        });
        this.isEdit = false;
        this.$emit('relaunch');
    }
}

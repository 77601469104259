import ElementUI from 'element-ui';
export default function (itemList) {
    return itemList.reduce((obj, item) => {
        const component = ElementUI[item.replace('El', '')];
        if (component) {
            const wrapper = `${item}Wrapper`;
            obj[item] = {
                name: item,
                functional: true,
                render: function (h, context) {
                    context.data.tag = 'div';
                    return h(wrapper, context.data, context.children);
                },
            };
            obj[wrapper] = component;
        }
        return obj;
    }, {});
}

import * as model from '@/model';
import * as http from '../http';
let { get, post, put, del } = http.http;

// my api
export function getCaptchaPicture<T = model.admin.IAdmin>(): Promise<model.admin.IData> {
    return http.http.get<T>('/query/annotation/login/v1/getCaptchaPicture', {
        responseType: 'blob',
        validateResponse(data) {
            return true;
        },
    });
}
export function loginByMobile<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/login/v1/loginByMobile', {
        data: data,
        validateResponse(data) {
            return true;
        },
    });
}
export function updatePassword<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/login/v1/updatePassword', { data: data });
}
export function listAnnoTask<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/listAnnoTask', { data: data });
}
export function getPreviousCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getPreviousCase', { data: data });
}
export function getNextCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getNextCase', { data: data });
}
export function getCaseById<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getCaseById', { data: data });
}
export function getTaskDashboardData<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getTaskDashboardData', { data: data });
}
export function getCasePic<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getCasePic', { data: data });
}
export function tempSaveCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/tempSaveCase', { data: data });
}
export function listTempSaveCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/listTempSaveCase', { data: data });
}
export function submitAnnoCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/submitAnnoCase', { data: data });
}
export function uploadCasePic<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.get<T>('/query/annotation/anno/v1/uploadCasePic');
}
export function getWaterMark<T = model.admin.IAdmin>(): Promise<model.admin.IData> {
    return http.http.get<T>('/query/annotation/common/v1/getWaterMark', {
        responseType: 'blob',
        validateResponse(data) {
            return true;
        },
    });
}
export function deleteAnnoCase<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/deleteAnnoCase', { data: data });
}
export function getCasePicKey<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/getCasePicKey', { data: data });
}
export function district<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/location/v1/district', { data: data });
}
export function setCaseDistrict<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/setCaseDistrict', { data: data });
}
export function logout<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/login/v1/logout', { data: data });
}
export function createSession<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/createSession', {
        data: data,
    });
}
export function chat<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/chat', {
        data: data,
    });
}
export function closeSession<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/query/annotation/anno/v1/closeSession', {
        data: data,
    });
}
export function listHistoryRound<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/llmChat/listHistoryRound', {
        data: data,
    });
}
export function predict<T = model.admin.IAdmin>(data: T): Promise<model.admin.IData> {
    return http.http.post<T>('/llmChat/predict', {
        data: data,
    });
}






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

@Component({})
export default class DynamicCom extends Vue {
    @Prop({})
    tableConf!: any;
    @Prop({ default: () => [] })
    tableData!: any;
    @Prop({ default: () => [] })
    summaryNum!: any; //表格合计
    @Prop({ default: () => [] })
    expandRowKeys!: any; //展开行
    //分页设置
    @Prop({ default: () => [] })
    paginationData!: any;
    @Prop({ default: false })
    noPagination!: boolean;
    pageNum = this.$route.query.pageNum;

    @Watch('pageNum', { immediate: true })
    onChange(cur) {
        if (cur) this.paginationData.current = Number(this.$route.query.pageNum);
    }

    get _paginationData() {
        return {
            total: this.tableData.length,
            size: 10,
            current: 1,
            ...this.paginationData,
        };
    }
    get tableStyle() {
        return this.tableConf.tableStyle || {};
    }
    get itemList() {
        return this.tableConf.itemList || [];
    }
    get tableEvent() {
        return this.tableConf.tableEvent || {};
    }
    summaryMethod() {
        //表尾合计行
        return this.summaryNum;
    }
    summaryDefaultMethod(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = '合计';
                return;
            }
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
            } else {
                sums[index] = '';
            }
        });

        return sums;
    }

    headerSlotEvent: any = {};
    judgeFilters(filters) {
        return filters ? 'filters' : '';
    }
    judgeFiltersMethod(filters) {
        return filters ? 'filter-method' : '';
    }
    get judgeExpandRowKeys() {
        return this.expandRowKeys.length > 0 ? 'expand-row-keys' : '';
    }

    filterMethod(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }

    //分页
    handleCurrentChange(current, size) {
        this.$emit('handleCurrentChange', { current, size });
    }
}

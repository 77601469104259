import Vue from 'vue';
import { PluginObject } from 'vue/types/plugin';
import { importAll } from '@/utils';

const pluginModules = importAll(
    require.context('@/plugins', false, /(?<!index)\.ts$/) // 除了index.ts以外的ts文件
);
const plugins = pluginModules.reduce((finalRoutes, routerModule) => finalRoutes.concat(routerModule.module), []); // map方法也可以

plugins.forEach((plugin: PluginObject<any>) => {
    Vue.use(plugin);
});




















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getListWorker, getChatGenerate } from '@/utils/multiRound';
import ModelDetail from './ModelDetail.vue';
import cloneDeep from 'lodash/cloneDeep';
import editContentDialog from './edit-content-dialog';
import DynamicForm from '@/components/dynamic-components/DynamicForm.vue';

interface IModel {
    model_id: number;
    temperature: number;
    top_k: number;
    top_p: number;
    frepetition_penalty: number;
    max_tokens: string;
    prompt: string;
    prefix?: string;
    suffix?: string;
}

@Component({
    components: {
        ModelDetail,
        DynamicForm,
    },
})
export default class MultiRoundDialogue extends Vue {
    @Prop() modelList!: IModel[];
    @Prop() index!: number;
    @Prop() firstQuestion!: string;
    @Prop() dialogueHistoryList!: any[];
    @Prop() defaultDialogueList!: any[];
    @Prop() isDoubleBlind!: number;
    @Prop() formConfig!: any;

    dialogueList = [];
    message = '';

    workerId = {};
    modelStarted = true;

    $refs: {
        scrollMark: HTMLDivElement;
    };

    beBeingSent = false; // 是否发送中
    isSelectModelContent = false;
    modelResultCollapse = [];
    selectDialogueIndex = null;
    currentModel = null;
    modelMap = {
        customReply: '',
    };
    formData = {};

    get modelSelectList() {
        if (!this.dialogueList[this.selectDialogueIndex]) {
            return null;
        }
        return Object.keys(this.modelMap || {}).filter((key) => key !== 'customReply');
    }

    handleConfirmSelectModelContent(dialogue) {
        if (this.modelSelectList?.length && !this.modelMap[this.currentModel]) {
            this.$message.warning('您未选择任意一种回复，请选择后再点击确认。');
            return false;
        }
        if (this.modelSelectList?.length && !this.modelMap[this.currentModel]) {
            this.$message.warning('您输入的内容为空，保存失败，请重新输入。');
            return false;
        }
        if (this.modelSelectList?.length) {
            dialogue.content = this.modelMap[this.currentModel];
            dialogue.modelMap = cloneDeep(this.modelMap);
            dialogue.currentModel = this.currentModel;
            this.modelMap.customReply = '';
        }
        Object.keys(this.formData).forEach((key) => {
            dialogue.formData[key] = this.formData[key];
        });
        this.formConfig?.formItemList?.forEach((item) => {
            this.formData[item.vModel] = item.defaultValue;
        });
        this.isSelectModelContent = false;
        this.selectDialogueIndex = null;
        this.currentModel = null;
        this.scrollBottom();
    }

    showCancelBtn = false;
    handleCancelSelectModelContent() {
        this.showCancelBtn = false;
        this.isSelectModelContent = false;
        this.selectDialogueIndex = null;
        this.currentModel = null;
        this.scrollBottom();
    }

    async handleSendMessage() {
        if (!this.message.trim()) {
            this.$message.warning('请输入内容！');
            return false;
        }

        if (this.beBeingSent) {
            this.$message.warning('请稍后');
            return false;
        }

        this.beBeingSent = true;
        this.isSelectModelContent = true;
        this.dialogueList.push({
            content: this.message,
            role: 'USER',
        });
        this.scrollBottom();
        try {
            const formData = {};
            this.formConfig?.formItemList?.forEach((item) => {
                formData[item.vModel] = item.defaultValue;
            });
            this.dialogueList.push({
                content: '',
                role: 'BOT',
                modelMap: {},
                currentModel: null,
                formData,
            });
            this.selectDialogueIndex = this.dialogueList.length - 1;
            const pAll = Object.keys(this.workerId).map((id) => {
                const model = this.modelList.find((m) => m.model_id === Number(id));
                return this.bulkAcquisition(model);
            });
            await Promise.all(pAll);
            this.modelResultCollapse = Object.keys(this.modelMap);
        } catch (error) {
            console.log('批量获取失败');
        } finally {
            this.beBeingSent = false;
            this.scrollBottom();
        }
    }

    async bulkAcquisition(model: IModel) {
        try {
            const prompts = this.dialogueList
                .map((d, i) => {
                    const prefix = model.prefix.replace('{round}', `${Math.floor(i / 2)}`);
                    const suffix = model.suffix.replace('{round}', `${Math.floor(i / 2)}`);
                    return d.role === 'USER'
                        ? {
                              role: d.role,
                              content: `${prefix}${d.content}${suffix}`,
                          }
                        : {
                              role: d.role,
                              content: d.content,
                          };
                })
                .filter((d) => d.role !== 'ERROR');
            if (model.prompt) {
                prompts.unshift({
                    content: model.prompt,
                    role: 'BOT',
                });
            }
            const params = {
                workerId: this.workerId[model.model_id],
                parameter: {
                    topP: model.top_p,
                    topK: model.top_k,
                    temperature: model.temperature,
                    maxOutputLength: model.max_tokens,
                    repetitionPenalty: model.frepetition_penalty,
                },
                prompts,
                promptPrefix: model.prefix,
                promptSuffix: model.suffix,
            };
            this.message = '';
            await getChatGenerate(params, (progressEvent) => {
                const responseMessageList = progressEvent.currentTarget.responseText.split('data:');
                let data = null;
                try {
                    data = JSON.parse(responseMessageList.at(-1).replace(/\n\n$/, ''));
                } catch (e1) {
                    try {
                        data = JSON.parse(responseMessageList.at(-2).replace(/\n\n$/, ''));
                    } catch (e2) {
                        console.log('JSON解析失败', e1, e2, responseMessageList);
                    }
                }
                if (data) {
                    if (!this.modelMap[model.model_id]) {
                        this.$set(this.modelMap, model.model_id, data.result);
                    } else {
                        this.modelMap[model.model_id] = data.result;
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    scrollBottom() {
        setTimeout(() => {
            this.$refs.scrollMark?.scrollIntoView({ block: 'end', behavior: 'smooth' });
        });
    }

    handleEnter(e) {
        if ([8, 13].includes(e.keyCode)) {
            if (!this.message) {
                return false;
            }
            e.preventDefault();
            this.handleSendMessage();
        }
    }

    async getWorkerId() {
        try {
            const requestList = this.modelList.map((model) => getListWorker(model.model_id));
            const res = await Promise.all(requestList);
            res.forEach((item, index) => {
                this.workerId[this.modelList[index].model_id] =
                    item.data.filter((r) => r.status === 'RUNNING').sort((a, b) => b.expireTime - a.expireTime)[0]?.workerId || null;
            });
            Object.keys(this.workerId).forEach((key) => {
                if (!this.workerId[key]) {
                    this.$message.warning(`模型Id为${key}的模型未启动成功。`);
                    throw new Error('模型未启动');
                }
            });
            this.modelStarted = true;
        } catch (error) {
            this.modelStarted = false;
            console.log(error, '获取失败');
            // $errorNotice({ title: `服务端错误`, message: '' /*JSON.stringify(e)*/ });
        }
    }

    async handleGetWorkerId() {
        await this.getWorkerId();
        if (this.$route.name !== 'annotation-work') {
            return false;
        }
        if (this.firstQuestion && this.modelStarted && !this.dialogueHistoryList?.length) {
            this.message = this.firstQuestion;
            this.handleSendMessage();
        }
    }

    handleEditContent(editContentIndex, message) {
        editContentDialog(message)
            .then((editMsg) => {
                this.dialogueList[editContentIndex].content = editMsg;
            })
            .catch(() => {
                //
            });
    }

    handleCarryOut(carryOutIndex, msg) {
        this.$mtd
            .confirm({
                title: '确认信息',
                message: '此操作将清空该条对话之后的对话内容，是否继续编辑。',
                width: '430px',
                showCancelButton: true,
            })
            .then(() => {
                this.dialogueList = this.dialogueList.slice(0, carryOutIndex);
                this.message = msg;
                this.handleSendMessage();
            })
            .catch(() => {
                //
            });
    }

    handleUnfold(i) {
        this.modelMap = this.dialogueList[i].modelMap
            ? cloneDeep(this.dialogueList[i].modelMap)
            : {
                  customReply: '',
              };
        this.currentModel = this.dialogueList[i].currentModel || null;
        this.showCancelBtn = true;
        this.selectDialogueIndex = i;
        this.isSelectModelContent = true;
        if (!this.dialogueList[i].formData) {
            this.dialogueList[i].formData = {};
            this.formConfig?.formItemList?.forEach((item) => {
                this.$set(this.dialogueList[i].formData, item.vModel, item.defaultValue);
            });
        }
        Object.keys(this.formData).forEach((key) => {
            this.formData[key] = this.dialogueList[i].formData[key];
        });
    }

    relaunch() {
        this.handleCancelSelectModelContent();
        this.dialogueList = [];
        this.modelResultCollapse = [];
        if (this.defaultDialogueList) {
            this.dialogueList = cloneDeep(this.defaultDialogueList);
        }

        if (this.firstQuestion && this.modelStarted) {
            this.message = this.firstQuestion;
            this.handleSendMessage();
        }
    }

    transSaveData() {
        return {
            dialogueHistoryList: this.dialogueList,
            modelList: this.modelList,
        };
    }

    async init() {
        if (this.dialogueHistoryList) {
            this.dialogueList = cloneDeep(this.dialogueHistoryList);
        }
        if (!this.dialogueHistoryList && this.defaultDialogueList) {
            this.dialogueList = cloneDeep(this.defaultDialogueList);
        }
        this.formConfig?.formItemList?.forEach((item) => {
            this.$set(this.formData, item.vModel, item.defaultValue);
        });
        this.handleGetWorkerId();
    }

    mounted() {
        this.init();
    }
}

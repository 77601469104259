







import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import LiteEditor from '@it/parker/es/lite-editor';
import Menubar from '@it/parker/es/menubar';
import EditorRender from '@it/parker/es/render';
import { Providers } from '@it/parker/es/core';
import { HeadingExtension } from '@it/parker/es/citadel-pc/extensions/heading-extension';
import { FontExtension } from '@it/parker/es/citadel-pc/extensions/font-extension';
import { StrongExtension } from '@it/parker/es/citadel-pc/extensions/strong-extension';
import { EmExtension } from '@it/parker/es/citadel-pc/extensions/em-extension';
import { StrikethroughExtension } from '@it/parker/es/citadel-pc/extensions/strike-extension';
import { UnderlineExtension } from '@it/parker/es/citadel-pc/extensions/underline-extension';
import { ColorExtension } from '@it/parker/es/citadel-pc/extensions/color-extension';
import { SubExtension } from '@it/parker/es/citadel-pc/extensions/sub-extension';
import { SupExtension } from '@it/parker/es/citadel-pc/extensions/sup-extension';
import { AlignExtension } from '@it/parker/es/citadel-pc/extensions/align-extension';
import { IndentExtension } from '@it/parker/es/citadel-pc/extensions/indent-extension';
import { BulletListExtension } from '@it/parker/es/citadel-pc/extensions/bullet-list-extension';
import { OrderedListExtension } from '@it/parker/es/citadel-pc/extensions/ordered-list-extension';
import { ListItemExtension } from '@it/parker/es/citadel-pc/extensions/list-item-extension';
import { TaskListExtension } from '@it/parker/es/citadel-pc/extensions/task-list-extension';
import { TaskItemExtension } from '@it/parker/es/citadel-pc/extensions/task-item-extension';
import { TableExtension } from '@it/parker/es/citadel-pc/extensions/table-extension';
import { NoteExtension } from '@it/parker/es/citadel-pc/extensions/note-extension';
import { CollapseExtension } from '@it/parker/es/citadel-pc/extensions/collapse-extension';
import { ImageExtension } from '@it/parker/es/citadel-pc/extensions/image-extension';
import { LinkExtension } from '@it/parker/es/citadel-pc/extensions/link-extension';
import { DrawioExtension } from '@it/parker/es/citadel-pc/extensions/drawio-extension';
import { MinderExtension } from '@it/parker/es/citadel-pc/extensions/minder-extension';
import { StatusExtension } from '@it/parker/es/citadel-pc/extensions/status-extension';
import { CodeExtension } from '@it/parker/es/citadel-pc/extensions/code-extension';
import { AttachmentExtension } from '@it/parker/es/citadel-pc/extensions/attachment-extension';
import { VideoExtension } from '@it/parker/es/citadel-pc/extensions/video-extension';
import { AudioExtension } from '@it/parker/es/citadel-pc/extensions/audio-extension';
import { CatalogExtension } from '@it/parker/es/citadel-pc/extensions/catalog-extension';
import { MarkdownExtension } from '@it/parker/es/citadel-pc/extensions/markdown-extension';

// import { TableProvider } from './providers/table';
// import { ImageProvider } from './providers/image';
// import { LinkProvider } from './providers/link';
// import { DrawioProvider } from './providers/drawio';
// import { MinderProvider } from './providers/minder';
// import { AttachmentProvider } from './providers/attachment';
// import { VideoProvider } from './providers/video';
// import { AudioProvider } from './providers/audio';

import BulletListRender from '@it/parker/es/citadel-pc/extensions/bullet-list-extension/render';
import OrderedListRender from '@it/parker/es/citadel-pc/extensions/ordered-list-extension/render';
import TaskListRender from '@it/parker/es/citadel-pc/extensions/task-list-extension/render';
import TableRender from '@it/parker/es/citadel-pc/extensions/table-extension/render';
import NoteRender from '@it/parker/es/citadel-pc/extensions/note-extension/render';
import CollapseRender from '@it/parker/es/citadel-pc/extensions/collapse-extension/render';
import ImageRender from '@it/parker/es/citadel-pc/extensions/image-extension/render';
import LinkRender from '@it/parker/es/citadel-pc/extensions/link-extension/render';
import DrawioRender from '@it/parker/es/citadel-pc/extensions/drawio-extension/render';
import MinderRender from '@it/parker/es/citadel-pc/extensions/minder-extension/render';
import StatusRender from '@it/parker/es/citadel-pc/extensions/status-extension/render';
import CodeRender from '@it/parker/es/citadel-pc/extensions/code-extension/render';
import AttachmentRender from '@it/parker/es/citadel-pc/extensions/attachment-extension/render';
import VideoRender from '@it/parker/es/citadel-pc/extensions/video-extension/render';
import AudioRender from '@it/parker/es/citadel-pc/extensions/audio-extension/render';
import CatalogRender from '@it/parker/es/citadel-pc/extensions/catalog-extension/render';
import MarkdownRender from '@it/parker/es/citadel-pc/extensions/markdown-extension/render';
// 定义一个interface的接口（cell）
@Component
export default class RichText extends Vue {
    @Prop()
    cell!: any;
    @Prop()
    value!: any;

    @Ref()
    editorContainer!: HTMLDivElement;
    @Ref()
    menubar!: HTMLDivElement;

    editorIns: LiteEditor | null = null;
    menuBarIns: Menubar | null = null;
    renderIns: EditorRender | null = null;
    domId = `${Math.random()}`.slice(-6);

    initEditor() {
        const providers = new Providers();
        this.editorIns = new LiteEditor(this.editorContainer, {
            providers,
            extensions: [
                new HeadingExtension(),
                new FontExtension(),
                new StrongExtension(),
                new EmExtension(),
                new StrikethroughExtension(),
                new UnderlineExtension(),
                new ColorExtension(),
                new SubExtension(),
                new SupExtension(),
                new AlignExtension(),
                new IndentExtension(),
                new BulletListExtension(),
                new OrderedListExtension(),
                new ListItemExtension(),
                new TaskItemExtension(),
                new TaskListExtension(),
                new TableExtension(),
                new NoteExtension(),
                new CollapseExtension(),
                new ImageExtension(),
                new LinkExtension(),
                new DrawioExtension(),
                new MinderExtension(),
                new StatusExtension(),
                new CodeExtension(),
                new AttachmentExtension(),
                new VideoExtension(),
                new AudioExtension(),
                new CatalogExtension(),
                new MarkdownExtension(),
            ],
            // defaultValue: this.cell.itemList[0].value,
            defaultValue: this.value,
            placeholder: '请输入内容...',
        });
        this.menuBarIns = new Menubar(this.menubar);
        this.editorIns.addMenubar(this.menuBarIns);
        this.editorIns.init(() => {
            this.editorIns.setCursor('end');
        });
        this.editorIns.onChange((str: any) => {
            // this.cell.itemList[0].value = str;
            // this.$emit('textContent', str);
            this.$emit('input', str);
        });
    }

    mounted() {
        this.initEditor();
        this.valueChange();
    }

    @Watch('value')
    valueChange() {
        if (this.editorIns?.value() !== this.value) {
            this.editorIns?.value(this.value);
            this.editorIns?.setCursor('end');
        }
    }
}
